import axios from 'axios';
import { getUrl } from 'urlConfig';
import { parsedDataInStorage, saveCreatedExpInStorage } from 'utils/Storage/storageFunctions';

const apiUrl = getUrl('api');

const experienceApi = axios.create({
  baseURL: apiUrl,
});

const getToken = () => {
  const { token } = parsedDataInStorage('vacanted') || {};
  return token;
};

const buildHeaders = () => ({
  headers: {
    Authorization: getToken(),
  },
});

const getActiveUserExperience = async () => {
  try {
    const response = await experienceApi.get('/experiences/active', buildHeaders());
    return response.data;
  } catch (error) {
    if (error?.response?.status === 404) return null;
    console.error(error);
    throw error;
  }
};

const createExperience = async (experience) => {
  try {
    const response = await experienceApi.post('/experiences', experience, buildHeaders());
    if (response.status === 201) {
      const { data } = response;
      saveCreatedExpInStorage({
        id: data.id,
        email: experience.email,
      });
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getExperience = async (experienceId) => {
  try {
    const response = await experienceApi.get(`/experiences/${experienceId}`, buildHeaders());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addSearchProfile = async (experienceId, searchProfile) => {
  try {
    const response = await experienceApi.post(`/experiences/${experienceId}/search_profiles`, searchProfile, buildHeaders());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addCandidate = async (experienceId, schoolId, source) => {
  try {
    const response = await experienceApi.post(`/experiences/${experienceId}/candidates`, {
      school_id: schoolId,
      source,
    }, buildHeaders());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteCandidate = async (experienceId, schoolId) => {
  try {
    const response = await experienceApi.delete(`/experiences/${experienceId}/candidates/${schoolId}`, buildHeaders());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const finishExperience = async (experienceId, finishData) => {
  try {
    const response = await experienceApi.post(`/experiences/${experienceId}/finish`, finishData, buildHeaders());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const experienceService = {
  createExperience,
  getActiveUserExperience,
  getExperience,
  addSearchProfile,
  addCandidate,
  deleteCandidate,
  finishExperience,
};

export default experienceService;
