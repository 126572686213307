import React from 'react';

import { onResolveOptions, isOtherAndEmpty } from '../../../helpers/flowUtils';
import ValueSelector from '../../selector';
import SchoolWinnerSelector from '../../school-winner-selector';
import RegistrationInfo from '../../registration-info';

const mapCandidateToFavs = ({ school }) => ({
  id: school.id,
  name: school.name,
  address: school.address,
  picture: school.logo,
});

const finalizeStep = (finalizeData, onChangeStepValue) => ({
  id: 'finalizeExperience',
  title: 'Finalizar búsqueda',
  pages: [
    {
      id: 'didYouRegister',
      title: '¿Matriculaste en algún centro educativo?',
      skip: ({ forceSchool }) => !!forceSchool,
      valid: ({ didYouRegister }) => !!didYouRegister?.value,
      Component: (selections) => (
        <ValueSelector
          options={onResolveOptions('didYouRegister', {
            didYouRegister: [
              {
                label: 'Sí',
                value: '1',
                name: 'didYouRegister',
              },
              {
                label: 'No',
                value: '0',
                name: 'didYouRegister',
              },
            ],
          }, selections)}
          type="single"
          onChange={(value) => onChangeStepValue('didYouRegister', value)}
        />
      ),
    },
    {
      id: 'whyNotRegistered',
      title: '¿Por qué finalizás la búsqueda sin matricular con un centro educativo?',
      skip: ({ didYouRegister }) => didYouRegister?.value !== '0' || true,
      valid: ({ whyNotRegistered }) => !!whyNotRegistered?.length
        && !whyNotRegistered.some(isOtherAndEmpty),
      Component: (selections) => (
        <ValueSelector
          options={onResolveOptions('whyNotRegistered', {
            whyNotRegistered: [
              {
                label: 'Me quedo en donde estoy',
                value: 'STAYING',
                name: 'whyNotRegistered',
              },
              {
                label: 'Pocos servicios',
                value: 'FEW_SERVICES',
                name: 'whyNotRegistered',
              },
              {
                label: 'Otro motivo',
                value: 'OTHER',
                name: 'whyNotRegistered',
                otherOptionData: {
                  placeholder: 'Explica tu motivo aquí...',
                },
              },
            ],
          }, selections)}
          type="multy"
          onChange={(value) => onChangeStepValue('whyNotRegistered', value)}
        />
      ),
    },
    {
      id: 'registeredSchool',
      title: '¿En qué centro matriculaste?',
      subtitle: 'Podés buscar el colegio o elegirlo de tus candidatos',
      skip: ({ didYouRegister, forceSchool }) => didYouRegister?.value === '0' || !!forceSchool,
      valid: ({ registeredSchool }) => !!registeredSchool,
      Component: (selections) => (
        <SchoolWinnerSelector
          favorites={
            finalizeData?.candidates?.filter(({ active }) => !!active)?.map(mapCandidateToFavs)
              ?? []
          }
          winner={selections?.registeredSchool ?? null}
          onChange={(value) => onChangeStepValue('registeredSchool', value)}
        />
      ),
    },
    {
      id: 'whySelectedSchool',
      title: ({ registeredSchool }) => `¿Por qué elegiste ${registeredSchool?.name ?? 'ese centro'}?`,
      skip: ({ didYouRegister }) => didYouRegister?.value === '0' || true,
      valid: ({ whySelectedSchool }) => !!whySelectedSchool?.length
        && !whySelectedSchool.some(isOtherAndEmpty),
      Component: (selections) => (
        <ValueSelector
          // eslint-disable-next-line react/destructuring-assignment
          options={onResolveOptions('whySelectedSchool', {
            whySelectedSchool: [
              {
                label: 'Por sus instalaciones',
                value: 'INFRASTRUCTURE',
                name: 'whySelectedSchool',
              },
              {
                label: 'Por su modelo educativo',
                value: 'EDUCATIONAL_MODEL',
                name: 'whySelectedSchool',
              },
              {
                label: 'Otro motivo',
                value: 'OTHER',
                name: 'whySelectedSchool',
                otherOptionData: {
                  placeholder: 'Explica tu motivo aquí...',
                },
              },
            ],
          }, selections)}
          type="multy"
          onChange={(value) => onChangeStepValue('whySelectedSchool', value)}
        />
      ),
    },
    {
      id: 'benefitsData',
      title: 'Completá esta información y accedé a tus beneficios por utilizar VacantED.',
      subtitle: 'Te pedimos estos datos para corroborar la inscripción con el centro educativo y poder aplicarte los beneficios.',
      skip: ({ didYouRegister }) => didYouRegister?.value === '0',
      Component: (selections) => (
        <RegistrationInfo
          current={selections}
          onChange={onChangeStepValue}
        />
      ),
    },
  ],
});

export default finalizeStep;
