/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import api from 'services/api';
import { useSearch } from 'hooks/SearchContext';
import VacantestDropdown from '../Dropdown/VacantestDropdown';
import localStorageVacantest from '../../localStorageVacantest';
import vacantestFunctions from './vacantestEducationalLevelsFunctions';
import eStyles from '../DefaultCardStyles.module.css';
import specificStyles from './VacantestEducationalLevels.module.css';

const VacantestEducationalLevels = ({ card, disableNext, setOnlyPreschoolSelected }) => {
  const [levels, setLevels] = useState({ options: [], mutatedOptions: [] });
  const [loading, setLoading] = useState(true);
  const [dropdownController, setDropdownController] = useState({ active: 1, max: 5 });
  const [educLevel, setEducLevel] = useState([]);
  const { locationInfo } = useSearch();
  const didMountRef = useRef(false);
  const {
    mutateLevelNames,
    mutateLevelOptions,
    checkMinMaxChildren,
    checkBtnAvailability,
    checkOptionName,
    checkOptionQuantity,
    checkNextAvailability,
  } = vacantestFunctions;

  const getInitialData = async () => {
    if (!didMountRef.current) {
      const defaultValues = localStorageVacantest.get(card);
      await api.get(`age/${locationInfo.countryId}`)
        .then((r) => r.data)
        .then((data) => {
          const mutatedData = mutateLevelNames(data);
          const dataWithFormattedNames = { options: mutatedData };

          mutateLevelOptions(dataWithFormattedNames, setLevels, defaultValues || []);
        });

      if (defaultValues?.length && defaultValues.every((edLevel) => edLevel.age)) {
        setDropdownController({
          ...dropdownController, active: defaultValues.length,
        });
        setEducLevel([...defaultValues]);
        disableNext(false);
      } else disableNext(true);
    }

    didMountRef.current = true;
    setTimeout(() => setLoading(false), 500);
  };
  useEffect(() => getInitialData(), []);

  const calculateIfOnlyPreschoolSelected = () => {
    const selectedAges = educLevel.map((level) => Number(level.age.match(/\d+/g)));
    const onlyPreschool = selectedAges.length && selectedAges.every((ageNumber) => ageNumber <= 5);
    setOnlyPreschoolSelected(onlyPreschool);
  };

  useEffect(() => {
    if (didMountRef.current) {
      localStorageVacantest.save(card, educLevel);
      calculateIfOnlyPreschoolSelected();
      disableNext(false);
    }
  }, [educLevel]);

  const saveEducationalLevel = (lvl, id) => {
    const aux = educLevel;

    aux[id - 1] = { ...lvl, qty: aux[id - 1]?.qty || 1 };

    setEducLevel([...aux]);
    mutateLevelOptions(levels, setLevels, aux);
  };

  const changeChildrenQuantity = (id, op) => {
    const aux = educLevel;
    aux[id - 1].qty = checkMinMaxChildren(aux[id - 1].qty, op);
    setEducLevel([...aux]);
  };

  const addLevelDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const removeLevelDropdown = (id) => {
    const mutatedLevels = educLevel;
    if (mutatedLevels.length === id) mutatedLevels.pop();

    setEducLevel([...mutatedLevels]);
    mutateLevelOptions(levels, setLevels, mutatedLevels);

    if (dropdownController.active > 1) {
      setDropdownController({ ...dropdownController, active: dropdownController.active - 1 });
    }
  };

  const renderAddDropdownButton = () => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => addLevelDropdown()}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlinePlus size={25} color="#333" />
      </div>
      <p>Agrega otro nivel</p>
    </button>
  );

  const renderRemoveDropdownButton = (id) => (
    <button
      type="button"
      className={specificStyles.vacantest__add__level__button}
      onClick={() => removeLevelDropdown(id)}
    >
      <div
        type="button"
        className={specificStyles.vacantest__add__dropdown__btn}
      >
        <AiOutlineMinus size={25} color="#333" />
      </div>
      <p>Quita un nivel</p>
    </button>
  );

  const addBtnConditions = (ddArr, id) => ddArr[ddArr.length - 1] === id
    && ddArr.length < dropdownController.max
    && checkNextAvailability(educLevel, dropdownController);

  const removeBtnConditions = (ddArr, id) => ddArr[ddArr.length - 1] === id
    && ddArr.length > 1;

  const renderDropdowns = () => {
    const dropdownsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdownsArray.push(i);
    }

    return dropdownsArray.map((id) => (
      <div key={id}>
        <div className={specificStyles.vacantest__dropdown__container}>
          <VacantestDropdown
            option={checkOptionName(educLevel, id)}
            options={levels.mutatedOptions}
            setOption={(e) => saveEducationalLevel(e, id)}
          />
        </div>
        <p>Cantidad de hijos que cursarán este nivel</p>
        <div className={specificStyles.vacantest__quantity__container}>
          <button
            type="button"
            className={specificStyles.vacantest__quantity__btn}
            onClick={() => changeChildrenQuantity(id, '-')}
            disabled={checkBtnAvailability(educLevel, id, '-')}
          >
            <AiOutlineMinus size={20} color="#333" />
          </button>
          <span className={specificStyles.quantity__span}>
            {checkOptionQuantity(educLevel, id)}
          </span>
          <button
            type="button"
            className={specificStyles.vacantest__quantity__btn}
            onClick={() => changeChildrenQuantity(id, '+')}
            disabled={checkBtnAvailability(educLevel, id, '+')}
          >
            <AiOutlinePlus size={20} color="#333" />
          </button>
        </div>
        <hr />
        {addBtnConditions(dropdownsArray, id)
          ? renderAddDropdownButton()
          : null}
        {removeBtnConditions(dropdownsArray, id)
          ? renderRemoveDropdownButton(id)
          : null}
      </div>
    ));
  };

  return (
    <div className={eStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>Niveles educativos</h3>
          <p>
            ¿Qué niveles educativos debería abarcar la institución que buscas para tus hijos?
          </p>

          {dropdownController.active > 0 && renderDropdowns()}
        </>
      ) : (
        <p className={eStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestEducationalLevels;

VacantestEducationalLevels.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
  setOnlyPreschoolSelected: PropTypes.func.isRequired,
};
