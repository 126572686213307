import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../Vacantest.module.css';

const tips = [
  {
    title: 'Importante',
    content: 'Debes al menos seleccionar una religión ',
  },
];

const VacantestLocationTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default VacantestLocationTips;
