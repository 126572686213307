import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import { useSearch } from 'hooks/SearchContext';
import localStorageVacantest from '../../localStorageVacantest';
import mStyles from '../DefaultCardStyles.module.css';

const VacantestManagement = ({ card, disableNext }) => {
  const { locationInfo } = useSearch();
  const [checkboxesData, setCheckboxesData] = useState([]);
  const [vacantestData, setVacantestData] = useState({});
  const [loading, setLoading] = useState(true);
  const didMountRef = useRef(false);

  const getInitialData = async () => {
    if (!didMountRef.current) {
      await api.get(`public/${locationInfo.countryId}`)
        .then((r) => setCheckboxesData(r.data));

      const defaultValues = localStorageVacantest.get(card);
      if (defaultValues) {
        setVacantestData(defaultValues);
        disableNext(false);
      } else disableNext(true);
      setLoading(false);
      didMountRef.current = true;
    }
  };
  useEffect(() => getInitialData(), []);

  const handleCheckboxToggle = (cb, checked) => {
    const parsedData = JSON.parse(cb);

    const newData = { ...vacantestData, [parsedData.id]: checked };
    if (checked && vacantestData.indiferent) newData.indiferent = false;
    setVacantestData(newData);
  };

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageVacantest.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      const cardValid = Object.values(vacantestData).includes(true);
      if (cardValid) saveCardInLocalStorage(vacantestData);
      else removeCardFromLocalStorage();
    }
  }, [vacantestData]);

  const handleMainCheckboxToggle = (e) => setVacantestData({ indiferent: e.target.checked });

  return (
    <div className={mStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>Tipo de institución educativa</h3>
          <p>
            ¿Prefieres priorizar algunas de estas opciones?
            Puedes elegir las opciones que desees.
          </p>
          <div className={mStyles.vacantest__section__container}>
            {checkboxesData.map((cd) => cd.name !== '' && (
              <label htmlFor={cd.id} className={mStyles.vacantest__checkbox__container} key={cd.id}>
                <input
                  type="checkbox"
                  className={mStyles.vacantest__checkbox}
                  id={cd.id}
                  // disabled={vacantestData.indiferent}
                  checked={vacantestData[cd.id] || false}
                  onChange={(e) => handleCheckboxToggle(JSON.stringify(cd), e.target.checked)}
                />
                <p className={mStyles.vacantest__checkbox__label}>{cd.name}</p>
              </label>
            ))}
          </div>
          <hr />
          <div className={mStyles.vacantest__section__container}>
            <h3>¿O te es indiferente?</h3>
            <label className={mStyles.vacantest__checkbox__container} htmlFor="indiferent">
              <input
                type="checkbox"
                className={mStyles.vacantest__checkbox}
                id="indiferent"
                checked={vacantestData.indiferent || false}
                onChange={(e) => handleMainCheckboxToggle(e)}
              />
              <p className={mStyles.vacantest__checkbox__label}>Si, me es indiferente</p>
            </label>
          </div>
        </>
      ) : (
        <p className={mStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestManagement;

VacantestManagement.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
