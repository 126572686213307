import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../Vacantest.module.css';

const tips = [
  /*
  {
    title: 'Importante',
    content: 'Es probable que alguna institución de la lista de resultados
    de este test pueda enviarte información institucional por email. En el
    caso que solo estés explorando, ninguna institución te enviará información institucional.',
  },
  */
];

const VacantestStartDateTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default VacantestStartDateTips;
