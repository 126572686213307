import React from 'react';
import PropTypes from 'prop-types';
import specificStyles from './Checkboxes.module.css';

const Checkboxes = ({ rows, handleOnClick, itemChecked }) => {
  const checkboxesOptions = [
    { id: 0, name: 'Baja' },
    { id: 1, name: 'Media' },
    { id: 2, name: 'Alta' },
  ];

  const checkIfRadioIsChecked = (index, priorityName) => {
    if (itemChecked[1]) {
      return itemChecked[index].priority.value === priorityName;
    }
    return true;
  };

  return (
    <>
      <div className={specificStyles.vacantest__checkboxes__row}>
        <div className={specificStyles.vacantest__checkboxes__item__name} />
        <div className={specificStyles.vacantest__checkboxes}>
          {checkboxesOptions.map((option) => (
            <div
              className={specificStyles.vacantest__individual__checkbox__title}
              key={option.id}
            >
              <h3>{option.name}</h3>
            </div>
          ))}
        </div>
      </div>
      {rows.map((row) => (
        <div key={row.id} className={specificStyles.vacantest__checkboxes__row}>
          <div
            className={specificStyles.vacantest__checkboxes__item__name}
          >
            <p>{row.name}</p>
          </div>
          <div className={specificStyles.vacantest__checkboxes}>
            {checkboxesOptions.map((option) => (
              <div
                className={specificStyles.vacantest__checkbox__individual__container}
                key={option.id}
              >
                <input
                  className={specificStyles.vacantest__checkbox}
                  type="radio"
                  name={row.id}
                  onChange={(element) => handleOnClick(element)}
                  value={option.name}
                  checked={checkIfRadioIsChecked(row.id, option.name)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default Checkboxes;

Checkboxes.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  itemChecked: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
