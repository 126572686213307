import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import countryService from 'services/countryService';
import api from 'services/api';
import localStorageVacantest from '../../localStorageVacantest';
import VacantestDropdown from '../Dropdown/VacantestDropdown';

import rStyles from '../DefaultCardStyles.module.css';
import specificStyles from './specific_styles.module.scss';

const buildRangeName = ({ from, to, name: defaultName }, ix) => {
  const finalRange = { id: ix, from, to };
  let rangeName = '';
  if (to === 0) rangeName = '$0 (No puedo pagar una cuota)';
  if (typeof to === 'number' && to) rangeName = `Hasta $${to.toLocaleString('es-UY')}`;
  if (!to && typeof from === 'number' && from) rangeName = `Más de $${from.toLocaleString('es-UY')}`;
  finalRange.name = defaultName || rangeName;
  return finalRange;
};

const VacantestCosts = ({ card, disableNext, onlyPreschoolSelected }) => {
  const countryId = countryService.getCurrentCountryId();
  const [scholarship, setScholarship] = useState('Yes');
  const didMountRef = useRef(false);

  const firstOption = { name: 'Seleccionar rango' };
  const [elementarySchoolCostOptions, setElementarySchoolCostOptions] = useState([]);
  const [elementarySchoolCost, setElementarySchoolCost] = useState(
    firstOption,
  );
  const [highschoolCostOptions, setHighschoolCostOptions] = useState([]);
  const [highschoolCost, setHighschoolCost] = useState(
    firstOption,
  );

  const isNotValid = () => {
    if (onlyPreschoolSelected && typeof elementarySchoolCost.id !== 'number') return true;
    if (!onlyPreschoolSelected && typeof highschoolCost.id !== 'number') return true;
    if (!scholarship) return true;
    return false;
  };

  useEffect(() => {
    if (didMountRef.current) {
      localStorageVacantest.save(
        card, { elementarySchoolCost, highschoolCost, scholarship },
      );
      disableNext(isNotValid());
    }
  }, [elementarySchoolCost, highschoolCost, scholarship]);

  const defaultValueIsValid = (options, { from, to }) => (
    options.some((option) => (
      from === option.from
      && to === option.to
    ))
  );

  const getInitialData = async () => {
    if (!didMountRef.current) {
      const response = await api.get(`rangeprice/${countryId}/public`);
      const rangesByLevel = response.data;
      setElementarySchoolCostOptions(rangesByLevel.preschool.map(buildRangeName));
      setHighschoolCostOptions(rangesByLevel.school.map(buildRangeName));
      didMountRef.current = true;

      const defaultValues = localStorageVacantest.get(card);
      if (defaultValues) {
        if (defaultValueIsValid(rangesByLevel.preschool, defaultValues.elementarySchoolCost)) {
          setElementarySchoolCost(defaultValues.elementarySchoolCost);
        }
        if (defaultValueIsValid(rangesByLevel.school, defaultValues.highschoolCost)) {
          setHighschoolCost(defaultValues.highschoolCost);
        }
        if (defaultValues.scholarship) {
          setScholarship(defaultValues.scholarship);
        }
      } else {
        disableNext(true);
      }
    }
  };

  useEffect(() => getInitialData(), []);

  return (

    <div className={rStyles.vacantest__card__content}>

      <>

        <h3>Inversión estimada</h3>
        <p>
          ¿Qué nivel de cuota mensual pagarías por la educación de un hijo?
        </p>

        <div className={rStyles.vacantest__dropdown__container}>
          {onlyPreschoolSelected && (
            <div className={specificStyles.last__dropdown}>
              <p className={specificStyles.bold_text}>En educación inicial (Hasta 5 años)</p>
              <VacantestDropdown
                option={elementarySchoolCost}
                options={elementarySchoolCostOptions}
                setOption={setElementarySchoolCost}
              />
              <p className={specificStyles.small_text}>
                Montos en
                {` ${countryService.getCurrencySymbolWithDescription()}`}
              </p>
            </div>
          )}
          {!onlyPreschoolSelected && (
            <div className={specificStyles.last__dropdown}>
              <p className={specificStyles.bold_text}>
                En primaria, secundaria y bachillerato (6 a 17 años)
              </p>
              <VacantestDropdown
                option={highschoolCost}
                options={highschoolCostOptions}
                setOption={setHighschoolCost}
              />
              <p className={specificStyles.small_text}>
                Montos en
                {` ${countryService.getCurrencySymbolWithDescription()}`}
              </p>
            </div>
          )}
          <hr />
          <div className="radio_btn">
            <p>
              ¿Te interesaría recibir de VACANTED™ por email recomendaciones de
              instituciones afines a
              tus preferencias que podrían flexibilizar condiciones económicas?
            </p>
            <div className="radios_btns">
              <form action="">
                <div className={specificStyles.radio_inputs}>
                  <input
                    className={rStyles.vacantest__checkbox}
                    type="radio"
                    checked={scholarship === 'Yes'}
                    value="Yes"
                    name="schollarship"
                    onChange={(e) => setScholarship(e.target.value)}
                  />
                  <p>Si, me interesa</p>
                </div>
                <div className={specificStyles.radio_inputs}>
                  <input
                    className={rStyles.vacantest__checkbox}
                    type="radio"
                    name="schollarship"
                    checked={scholarship === 'No'}
                    value="No"
                    onChange={(e) => setScholarship(e.target.value)}
                  />
                  <p>No me interesa</p>
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default VacantestCosts;

VacantestCosts.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
  onlyPreschoolSelected: PropTypes.bool.isRequired,
};
