import React, { useState, useEffect, useContext } from 'react';
import FlowContext from './context';
import FlowFooter from './footer';
import { applicablePagesNew } from '../../helpers/flowUtils';
import ExperienceMainNew from '../main/view';

import './styles/flow-styles.scss';

const Flow = () => {
  const flowContext = useContext(FlowContext);
  const {
    currentData, steps, setCurrentPageId,
  } = flowContext;
  const [customForwardAction, setCustomForwardAction] = useState();
  const [currentStep, setCurrentStep] = useState({
    index: 0,
    pageIndex: 0,
    applicablePages: applicablePagesNew(steps[0].pages, currentData),
  });

  useEffect(() => {
    const newPagesForCurrentStep = applicablePagesNew(steps[currentStep.index].pages, currentData);
    setCurrentStep((prevState) => ({
      ...prevState,
      applicablePages: newPagesForCurrentStep,
    }));
  }, [currentData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep.index, currentStep.pageIndex]);

  const descreasePage = () => {
    if (currentStep.pageIndex > 0) {
      setCurrentStep({
        ...currentStep,
        pageIndex: currentStep.pageIndex - 1,
      });
    } else {
      const newStepIndex = currentStep.index - 1;
      if (newStepIndex >= 0) {
        const newPages = applicablePagesNew(steps[newStepIndex].pages, currentData);
        setCurrentStep({
          index: newStepIndex,
          applicablePages: newPages,
          pageIndex: newPages.length - 1,
        });
      }
    }
  };

  const increasePage = () => {
    if (currentStep.pageIndex < currentStep.applicablePages.length - 1) {
      setCurrentStep({
        ...currentStep,
        pageIndex: currentStep.pageIndex + 1,
      });
    } else {
      const newStepIndex = currentStep.index + 1;
      if (newStepIndex < steps.length) {
        const newPages = applicablePagesNew(steps[newStepIndex].pages, currentData);
        setCurrentStep({
          index: newStepIndex,
          applicablePages: newPages,
          pageIndex: 0,
        });
      }
    }
  };

  const currentPage = currentStep.applicablePages[currentStep.pageIndex];

  useEffect(() => {
    if (currentPage?.id) setCurrentPageId(currentPage.id);
  }, [currentPage]);

  return (
    <FlowContext.Provider
      value={{
        ...flowContext,
        customForwardAction,
        setCustomForwardAction,
        currentStep,
        descreasePage,
        increasePage,
        allowForwardAction: (currentPage?.valid && currentPage.valid(currentData))
          || (!currentPage?.valid && currentData[currentPage?.id]),
      }}
    >
      <ExperienceMainNew withFooter>
        <div className="flow-content">
          <span className="flow-step__title">{steps[currentStep.index]?.title?.toUpperCase()}</span>
          <span className="flow-page__title">
            {typeof currentPage?.title === 'function'
              ? currentPage.title(currentData)
              : currentPage?.title}
          </span>
          <span className="flow-page__subtitle">
            {typeof currentPage?.subtitle === 'function'
              ? currentPage.subtitle(currentData)
              : currentPage?.subtitle}
          </span>
          {/* <div className="divider" /> */}
          <div id={currentPage?.id} className="questions">
            {currentPage?.Component(currentData)}
          </div>
        </div>
      </ExperienceMainNew>
      <FlowFooter />
    </FlowContext.Provider>
  );
};

export default Flow;
