import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CounterSelector from './CounterSelector';
import RadioSelector from './RadioSelector';
import ListSelector from './ListSelector';
import TextAreaSelector from './TextAreaSelector';

const ListRadioSelector = ({ options, onChange }) => {
  const [radioSelectedDefault] = options?.filter((option) => option.checked) || [];
  const [radioOptionSelected, setRadioOptionSelected] = useState({
    value: radioSelectedDefault?.value,
  });

  const onRadioChangeHandler = (event) => {
    const newValue = {
      value: event.target.value,
    };

    const selected = options.find((option) => option.value === newValue.value);
    if (selected?.list) {
      newValue.list = [];
    }

    if (selected?.counter) {
      newValue.count = 1;
    }

    if (selected?.text) {
      newValue.text = '';
    }

    setRadioOptionSelected(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const onCountChangeHandler = (newCount) => {
    const newState = { ...radioOptionSelected };
    newState.count = newCount;
    setRadioOptionSelected(newState);

    if (onChange) {
      onChange(newState);
    }
  };

  const onOtherTextChangeHandler = (newText) => {
    const newState = { ...radioOptionSelected };
    newState.text = newText;
    setRadioOptionSelected(newState);

    if (onChange) {
      onChange(newState);
    }
  };

  const onListChangeHandler = (id) => {
    const newState = { ...radioOptionSelected };
    /* if (radioOptionSelected.list?.includes(id)) {
      newState.list = newState.list.filter((item) => item !== id);
    } else {
      newState.list.push(id);
    } */

    newState.list = [id];
    setRadioOptionSelected(newState);

    if (onChange) {
      onChange(newState);
    }
  };

  return options?.map((option) => {
    const {
      name, label, value, counter, list, disabled, otherOptionData, checked,
    } = option;
    return (
      <React.Fragment key={value}>
        <RadioSelector
          key={value}
          name={name}
          label={label}
          checked={checked}
          value={value}
          onChange={onRadioChangeHandler}
          counter={counter}
          disabled={disabled}
        >
          {(!!counter
              && radioOptionSelected?.count
              && radioOptionSelected.value === value
          )
              && (
                <CounterSelector
                  count={radioOptionSelected.count}
                  label={counter.label}
                  onChange={(newCount) => onCountChangeHandler(newCount)}
                />
              )}
          {(!!list?.length && radioOptionSelected.value === value)
              && (
                <ListSelector
                  list={list.map((item) => (
                    { ...item, checked: radioOptionSelected.list?.includes(item.id) }
                  ))}
                  onClick={(id) => onListChangeHandler(id)}
                />
              )}
        </RadioSelector>
        {otherOptionData && radioOptionSelected.value === value && (
          <TextAreaSelector
            placeholder={otherOptionData.placeholder}
            value={radioOptionSelected.text || ''}
            onChange={onOtherTextChangeHandler}
          />
        )}
      </React.Fragment>
    );
  });
};

ListRadioSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
      checked: PropTypes.bool,
      counter: PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
      }),
      list: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          checked: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default ListRadioSelector;
