import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageVacantest from 'components/Vacantest/localStorageVacantest';
import rStyles from '../DefaultCardStyles.module.css';
import Checkboxes from '../Checkboxes/Checkboxes';

const VacantestMainFactors = ({ card, disableNext }) => {
  const mainFactorsCollection = [
    { id: 0, name: 'Tecnología', priority: { status: false, value: '' } },
    { id: 1, name: 'Actividades extracurriculares', priority: { status: false, value: '' } },
    { id: 2, name: 'Resultados académicos', priority: { status: false, value: '' } },
    { id: 3, name: 'Existencia de comedor', priority: { status: false, value: '' } },
    { id: 4, name: 'Transporte escolar', priority: { status: false, value: '' } },
    { id: 5, name: 'Infraestructura para impartir clases virtuales', priority: { status: false, value: '' } },
    { id: 6, name: 'Inclusión de alumnos con capacidades diferentes', priority: { status: false, value: '' } },
    { id: 7, name: 'Actividades deportivas', priority: { status: false, value: '' } },
    { id: 8, name: 'Infraestructura deportiva', priority: { status: false, value: '' } },
  ];

  const [vacantestData, setVacantestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const didMountRef = useRef(false);

  const getInitialData = () => {
    if (!didMountRef.current) {
      const defaultValues = localStorageVacantest.get(card);
      setVacantestData(defaultValues || mainFactorsCollection);
      didMountRef.current = true;
    }
    setLoading(false);
  };
  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    // se houver vacantest[1] significa que tb terà ate o fim da lista
    if (vacantestData[1]) {
      return vacantestData.every((item) => item.priority.status);
    }
    return false;
  };

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageVacantest.save(card, data);
    disableNext(false);
  };

  const handleOnClick = (element) => {
    const { value, checked, name } = element.target;
    const data = vacantestData;
    data[name].priority = { status: checked, value };
    setVacantestData([...data]);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(vacantestData);
  }, [vacantestData]);

  return (
    <div className={rStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <div>
            <h3>Valoración de factores</h3>
            <p>
              ¿Qué importancia tienen los siguientes factores para la educación de tus hijos?
            </p>
          </div>
          <div className={rStyles.vacantest__section__container}>
            <Checkboxes
              rows={mainFactorsCollection}
              handleOnClick={handleOnClick}
              itemChecked={vacantestData}
            />
          </div>
        </>
      ) : (
        <p className={rStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestMainFactors;

VacantestMainFactors.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
