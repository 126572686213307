import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../Vacantest.module.css';

const tips = [
  {
    title: 'Acceso a condiciones económicas especiales',
    content: 'VACANTED™ puede ayudarte a encontrar una institución educativa afín a tus preferencias, incluso si tu capacidad de pago no cubre la totalidad de su costo. Para ello ejecuta el proceso de POSTULACIÓN en el área privada de familias, a continuación de este test. Asegurate de haber completado todos los items para avanzar a la siguiente sección.',
  },
];

const VacantestLocationTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.title}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.title}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.content}</p>
      </div>
    </div>
  ))
);

export default VacantestLocationTips;
