import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import localStorageVacantest from '../../localStorageVacantest';
import rStyles from '../DefaultCardStyles.module.css';
import specificStyles from './VacantestRange.module.css';

const VacantestRange = ({ card, disableNext }) => {
  const [rangeValue, setRangeValue] = useState(5);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRangeValue(localStorageVacantest.check(card)
      ? localStorageVacantest.get(card).range
      : rangeValue);
    disableNext(false);
    setTimeout(() => setLoading(false), 500);
  }, []);
  useEffect(() => localStorageVacantest.save(card, { range: rangeValue }), [rangeValue]);

  // function to get the range value to follow the range thumb
  function getThumbPosition() {
    const calc1 = ((Number(rangeValue) * 100) / 50);

    // first magic number sets the start and second sets the ending
    return `calc(${calc1}% + (${9 - calc1 * 0.39}px))`;
  }

  // custom style to set the value position reference the range thumb
  const outputCustomStyle = {
    left: `${getThumbPosition()}`,
    top: '-10px',
    color: '#7a8cb2',
    width: '50px',
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>Área de búsqueda</h3>
          <p>
            Ahora cuantifica los kilómetros alrededor del punto
            geográfico indicado para definir el área total de búsqueda.
          </p>

          <div className={specificStyles.vacantest__range__container}>
            <div className={specificStyles.vacantest__range__title}>
              <h5>Distancia (km)</h5>
            </div>
            <div className={`${specificStyles.vacantest__range} sidebar-range`}>
              <output style={outputCustomStyle}><strong>{`${rangeValue} km`}</strong></output>
              <input
                type="range"
                className={specificStyles.vacantest__range__input}
                name="range-value"
                value={rangeValue}
                onChange={({ target }) => setRangeValue(Number(target.value))}
                min="0"
                max="50"
              />
            </div>
            <div className={specificStyles.vacantest__range__underbar}>
              <p className={specificStyles.smaller__range}>0</p>
              <p className={specificStyles.smaller__range}>10</p>
              <p className={specificStyles.smaller__range}>20</p>
              <p className={specificStyles.smaller__range}>30</p>
              <p className={specificStyles.smaller__range}>40</p>
              <p className={specificStyles.smaller__range}>50</p>
            </div>
          </div>
        </>
      ) : (
        <p className={rStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestRange;

VacantestRange.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
