import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  createRef,
} from 'react';

import PropTypes from 'prop-types';
import {
  getSchools,
  getSchoolsByCoordinates,
  updateFilteredSchools,
} from 'utils/schools/schoolFunctions';

import countryService from 'services/countryService';
import api from 'services/api';

import { removeDuplicateCities, updateCityInfo } from 'utils/cities/citiesFunctions';
import { getCoordsBasedOnRoute } from 'utils/Coordinates/coordinatesFunctions';
import filterSchoolsByDistance from '../utils/SearchFilters/distanceFilter';

import useAddressCordinates from './useAddressCordinates';
import { useSchools } from './SchoolContext';
import useFilters from './useFilters';
import useTable from './useTable';

export const SearchContext = createContext();

const refFilter = createRef();
const refButton = createRef();

export const SearchContextProvider = ({ children }) => {
  const currentPathname = window.location.pathname;
  const countryId = countryService.getCurrentCountryId();
  const defaultUsedCoords = countryId === 101 ? {
    latitude: -34.8748804,
    longitude: -56.1704501,
  } : {
    latitude: -31.4200832,
    longitude: -64.1887761,
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [saveModalName, setSaveModalName] = useState('Mi Busqueda');
  const [notLoggedName, setNotLoggedName] = useState('');
  const [notLoggedOpen, setNotLoggedOpen] = useState(false);
  const [usedCoordinates, setUsedCoordinates] = useState(defaultUsedCoords);
  const rangeDefaultValue = 5;
  const [filters, setFilters] = useState({ 'range-value': rangeDefaultValue });
  const [tableData, setTableData] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [cities, setCities] = useState([]);
  const [cityInfo, setCityInfo] = useState({});

  const {
    schoolsData, setSchoolsData, schoolsLoadingState, setSchoolsLoadingState,
  } = useSchools();

  const {
    addressCoordinates, getCoordinatesByPlaceId, updateAddressSuggestions,
  } = useAddressCordinates();

  const clearAll = () => {
    setFilters({ 'range-value': rangeDefaultValue });
    setSchoolsData({ ...schoolsData, filteredData: schoolsData.rawData });
    setSearchTerm('');
  };

  function setDefaultLocationInfo() {
    if (countryId === 101) {
      return setLocationInfo({
        countryId,
        countryName: 'Uruguay',
        table_step: 3,
        name: 'Montevideo',
        cityId: 9116,
      });
    }
    if (countryId === 102) {
      return setLocationInfo({
        countryId,
        countryName: 'Argentina',
        table_step: 1,
        name: 'Ciudad de Córdoba',
        cityId: 1803,
      });
    }
    return locationInfo;
  }

  // db schools
  const querySchools = async (searchRoute) => {
    setSchoolsLoadingState(true);
    const schools = await getSchools(searchRoute);
    const coords = getCoordsBasedOnRoute(searchRoute, schools);
    setUsedCoordinates(coords);

    // default filters (range && sorted by distance)
    const defaultSortedSchools = schools.sort((s1, s2) => s1.distance - s2.distance);
    const schoolsWithinRange = filterSchoolsByDistance(coords, defaultSortedSchools, filters['range-value']);
    updateFilteredSchools(schools, schoolsWithinRange, setSchoolsData);
    setSchoolsLoadingState(false);
    clearAll();
  };

  // get table data
  async function fetchTable() {
    const table = await useTable(locationInfo);
    setTableData(table);
  }

  // set default query for when no search is made, or no location selected
  async function setDefaultQuery() {
    if (!Object.keys(tableData)?.length && !Object.keys(locationInfo)?.length) {
      setSchoolsLoadingState(true);
      setUsedCoordinates();
      fetchTable();
      // const table = await useTable(locationInfo);
      // setTableData(table);
      // await querySchools(`/school/searchcity/${locationInfo.cityId}`, table.steps);
    }
  }

  const querySchoolsWithCoordinates = async (searchRoute, addressCoords) => {
    setSchoolsLoadingState(true);
    const schools = await getSchoolsByCoordinates(searchRoute, addressCoords);

    if (!locationInfo) {
      setDefaultLocationInfo();
    }

    setUsedCoordinates(addressCoords);

    const defaultSortedSchools = schools.sort((s1, s2) => s1.distance - s2.distance);
    const schoolsWithinRange = filterSchoolsByDistance(addressCoords, defaultSortedSchools, filters['range-value']);
    updateFilteredSchools(schools, schoolsWithinRange, setSchoolsData);

    setFilters({ 'range-value': rangeDefaultValue });
    setSearchTerm('');
    setSchoolsLoadingState(false);
  };

  useEffect(() => {
    setDefaultLocationInfo();
  }, []);

  const useEffect1 = async (locationInfoParam) => {
    // location info
    if (Object.keys(locationInfoParam).length !== 0) {
      const getCitiesByCountryId = await api.get(`/city/${countryId}?active=true`);
      const filteredCities = removeDuplicateCities(getCitiesByCountryId);
      setCities(filteredCities);

      const { steps } = await useTable(locationInfoParam);

      const pathnamePermittedToDoQuerySchools = ['', '/', '/search', '/search/'];

      // eslint-disable-next-line max-len
      if (schoolsData.rawData.length === 0 && pathnamePermittedToDoQuerySchools.includes(currentPathname)) {
        if (locationInfoParam)querySchools(`school/searchcity/${locationInfoParam.cityId}`, steps);
      }
      await updateCityInfo(locationInfoParam, setCityInfo);
      fetchTable();
    }
  };

  useEffect(() => {
    useEffect1(locationInfo);
  }, [locationInfo]);

  // filter schools
  useEffect(() => {
    useFilters(
      schoolsData,
      setSchoolsData,
      filters,
      tableData,
      usedCoordinates,
    );
  }, [filters]);

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setUsedCoordinates,
        usedCoordinates,
        getCoordinatesByPlaceId,
        updateAddressSuggestions,
        setSearchTerm,
        schoolsLoadingState,
        schoolsData,
        setSchoolsData,
        setDefaultQuery,
        querySchools,
        querySchoolsWithCoordinates,
        clearAll,
        filters,
        setFilters,
        addressCoordinates,
        setLocationInfo,
        locationInfo,
        tableData,
        setTableData,
        refFilter,
        refButton,
        isSaveModalOpen,
        setIsSaveModalOpen,
        saveModalName,
        setSaveModalName,
        notLoggedName,
        setNotLoggedName,
        notLoggedOpen,
        setNotLoggedOpen,
        cities,
        cityInfo,
        setCityInfo,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  return context;
};

SearchContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
