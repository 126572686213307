/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useAppContext } from 'hooks/ApplicationContext';
import { useUserData } from 'hooks/UserContext';
import { schedulesData } from 'hooks/SchoolProfileContext/SchoolProfileSlice';
import api from 'services/api';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import regex from 'utils/Regex/regex';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import closeIcon from '../../assets_vacanted/close.svg';
import Chips from '../Chips';
import DropDownSelect from '../DropDownSelect';
import EducLevelsTranslator from '../../services/educLevelsTranslator';

import style from './styles.module.css';

const { levelNameToAge } = EducLevelsTranslator;

Modal.setAppElement('#root');

const getAvailableSeasonOptions = () => api.get('/seasons/active')
  .then(({ data: apiSeasons }) => {
    if (!apiSeasons) return [];

    const options = [];
    if (apiSeasons.current) {
      const { id, name } = apiSeasons.current;
      options.push(
        {
          id,
          label: `${name} (En curso)`,
        },
      );
    }
    if (apiSeasons.next) {
      const { id, name } = apiSeasons.next;
      options.push(
        {
          id,
          label: `${name} (Próximo comienzo)`,
        },
      );
    }
    options.push(
      {
        id: 0,
        label: 'Otro',
      },
    );
    return options;
  })
  .catch(() => console.error('Failed to get seasons') || []);

function ContactModal({
  isOpen,
  onRequestClose,
  schoolName,
  modalAction,
  schoolId,
  candidateSource,
}) {
  const vacantedData = parsedDataInStorage('vacanted');
  const loggedUser = vacantedData?.user;
  const initialName = loggedUser?.name?.replace('Usuario_Vacanted', '')?.replace('user_vacanted', '');
  const { userData, updateActiveExperience } = useUserData();
  const [fields, setFields] = useState({
    name: initialName || '',
    email: loggedUser?.email || '',
    phoneNumber: '',
    request_visit: false,
    accept_the_terms: false,
    do_not_allow_infos: false,
    messageToSchool: '',
    season: null,
  });
  const [phoneError, setPhoneError] = useState(false);
  const handlePhoneChange = (newValue) => {
    setFields({ ...fields, phoneNumber: newValue });
    setPhoneError(!matchIsValidTel(newValue));
  };
  const [error, setError] = useState([
    { errorName: 'name', msg: '', status: false },
    { errorName: 'email', msg: '', status: false },
    { errorName: 'phoneNumber', msg: '', status: false },
    { errorName: 'accept_the_terms', msg: '', status: false },
    { errorName: 'levels', msg: '', status: false },
    { errorName: 'messageToSchool', msg: '', status: false },
    { errorName: 'season', msg: '', status: false },
  ]);
  const [chips, setChips] = useState([]);
  const [responseMsg, setResponseMsg] = useState('');
  const [seasonOptions, setSeasonOptions] = useState([]);
  const schedules = useSelector(schedulesData);

  const {
    snackbarContext: { setSnackbar, closeSnackbar },
  } = useAppContext();

  useEffect(() => {
    getAvailableSeasonOptions().then(setSeasonOptions);
  }, []);

  useEffect(() => {
    if (!modalAction) return;

    setFields({
      ...fields,
      request_visit: modalAction === 'visit',
    });
  }, [modalAction]);

  const validateInputs = () => {
    const errArr = error;
    const nameIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'name'));
    const emailIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'email'));
    const termsAndConditionsIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'accept_the_terms'));
    const msgIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'messageToSchool'));
    const seasonIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'season'));

    if (fields.name === '') errArr[nameIndex] = { ...errArr[nameIndex], msg: 'Debe ingresar su nombre', status: true };
    if (fields.email === '') errArr[emailIndex] = { ...errArr[emailIndex], msg: 'Debe ingresar su email', status: true };
    if (fields.messageToSchool === '') errArr[msgIndex] = { ...errArr[msgIndex], msg: 'Debe ingresar un mensaje', status: true };
    if (!fields.accept_the_terms) errArr[termsAndConditionsIndex] = { ...errArr[termsAndConditionsIndex], msg: 'Debe aceptar los Términos y Condiciones', status: true };
    if (!fields.season) errArr[seasonIndex] = { ...errArr[seasonIndex], msg: 'Debe seleccionar el año de ingreso', status: true };

    const infosIndex = errArr.indexOf(errArr.find((e) => e.errorName === 'levels'));
    if (!chips?.length) {
      errArr[infosIndex] = {
        ...errArr[infosIndex],
        msg: 'Debe seleccionar al menos un nivel educativo',
        status: true,
      };
    } else {
      errArr[infosIndex] = {
        ...errArr[infosIndex],
        msg: '',
        status: false,
      };
    }

    setError([...errArr]);
  };

  const checkInputErrors = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'name':
        if (value && !regex.nameRegex.test(value.trim())) {
          const errArr = error;
          const errIndex = errArr.indexOf(errArr.find((err) => err.errorName === name));
          errArr[errIndex] = { ...errArr[errIndex], msg: 'Nombre inválido', status: true };
          setError([...errArr]);
        }
        break;
      case 'email':
        if (value && !regex.emailRegex.test(value.trim())) {
          const errArr = error;
          const errIndex = errArr.indexOf(errArr.find((err) => err.errorName === name));
          errArr[errIndex] = { ...errArr[errIndex], msg: 'Correo inválido', status: true };
          setError([...errArr]);
        }
        break;
      case 'phoneNumber':
        if (value && !regex.phoneRegex.test(value)) {
          const errArr = error;
          const errIndex = errArr.indexOf(errArr.find((err) => err.errorName === name));
          errArr[errIndex] = { ...errArr[errIndex], msg: 'Teléfono inválido', status: true };
          setError([...errArr]);
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e, typeRadio) => {
    const { name, value, checked } = e.target;

    const errArr = error;
    const errIndex = errArr.indexOf(errArr.find((err) => err.errorName === name));
    errArr[errIndex] = { ...errArr[errIndex], msg: '', status: false };
    setError([...errArr]);

    const newFields = { ...fields };
    if (typeRadio) newFields[name] = checked;
    else if (name === 'season') newFields[name] = value ? { id: value, name: seasonOptions.find(({ id }) => id === value)?.label } : { id: null };
    else newFields[name] = value;
    setFields(newFields);
  };

  /* const getEducationalLevels = (levels) => {
    if (levels) return { status: levels, levels: chips };

    return { status: levels, levels: [] };
  }; */

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    validateInputs();

    const dataToSend = {
      userEmail: fields.email,
      userName: fields.name,
      userPhoneNumber: phoneError ? null : fields.phoneNumber,
      messageToSchool: fields.messageToSchool,
      educationalLevels: chips.map((levelName) => ({ levelName, age: levelNameToAge(levelName) })),
      season: fields.season,
      candidateSource,
    };
    console.log('dataToSend', dataToSend);

    if (error.every((err) => !err.status)) {
      setResponseMsg('Enviando...');
      try {
        const endpoint = fields.request_visit ? 'sendinterviewrequest' : 'sendquestion';
        const response = await api.post(`/school/${endpoint}/${schoolId}`, dataToSend);
        if (response && response?.status === 200) {
          const { data: { experience_action } } = response;
          let message = `Enviamos tu solicitud a ${schoolName}. Te avisaremos por email cuando el centro responda.`;
          if (experience_action && userData?.loginStatus) {
            updateActiveExperience();
          }
          if (experience_action === 'candidate') {
            message = `Enviamos tu solicitud a ${schoolName} y lo agregamos a tu listado de candidatos. Te avisaremos por email cuando el centro responda.`;
          }
          setSnackbar({
            hirachy: 'success',
            text: message,
            action: 'Entendido',
            onClick: closeSnackbar,
          });
          onRequestClose();
        } else {
          setSnackbar({
            hirachy: 'error',
            text: 'Ocurrió un error. Por favor, intentá nuevamente.',
            action: 'Entendido',
            onClick: closeSnackbar,
          });
        }
      } catch (err) {
        let errorMessage = 'Ocurrió un error. Por favor, intentá nuevamente.';
        if (err?.response?.data?.error) {
          errorMessage = err.response.data.error;
        }
        setResponseMsg(errorMessage);
        setSnackbar({
          hirachy: 'error',
          text: errorMessage,
          action: 'Entendido',
          onClick: closeSnackbar,
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={style['react-modal-overlay']}
      className={style['react-modal-content']}
    >
      <div className={style['header-contact']}>
        <h2 className={style['modal-header']}>
          {fields.request_visit ? 'Solicitud de entrevista con ' : 'Consulta a '}
          {`${schoolName}`}
        </h2>
        <button
          type="button"
          onClick={onRequestClose}
          className={style['modal-close']}
        >
          <img src={closeIcon} alt="cerrar modal" />
        </button>
      </div>
      <hr />
      {/* <p className={style['modal-info-header']}>
        Al confirmar este envío, estarás permitiendo a VacantED™
        dar a conocer a la institución educativa tu interés en realizarles una consulta.
        La institución educativa será quien, a partir de esta comunicación,
        se contacte contigo para atender tu solicitud.
      </p> */}
      <form>
        <div className={style['contact-container']}>
          {!initialName && (
          <div>
            {/* <span className={`${style['name-span']} ${style.name}`}>Nombre completo</span> */}
            <input
              className={error[0].status ? style['contact-input__error'] : style['contact-input']}
              type="text"
              name="name"
              placeholder="Nombre completo"
              value={fields.name}
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => checkInputErrors(e)}
              maxLength={40}
            />
            {error[0].status && <p className={style.error}>{error[0].msg}</p>}
          </div>
          )}
          {!loggedUser?.email && (
          <div>
            {/* <span className={`${style.email} ${style['email-span']}`}>Email</span> */}
            <input
              className={error[1].status ? style['contact-input__error'] : style['contact-input']}
              type="text"
              name="email"
              placeholder="Email"
              value={fields.email}
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => checkInputErrors(e)}
              maxLength={40}
            />
            {error[1].status && <p className={style.error}>{error[1].msg}</p>}
          </div>
          )}
          <div className={style['contact-container-phone']}>
            {/* <span className={`${style['doble-span']} ${style.phone} ${style['phone-span']}`}>
              <div>Teléfono</div>
              <div>Opcional</div>
            </span> */}
            <MuiTelInput
              InputProps={{
                // className: 'congrats__form__input',
                size: 'small',
                name: 'phoneNumber',
                // error: !!phoneError,
                // helperText: phoneError ? 'El número no es válido' : '',
              }}
              fullWidth
              label="WhatsApp (Opcional)"
              defaultCountry="UY"
              value={fields.phoneNumber}
              onChange={handlePhoneChange}
            />
            {false && (
              <input
                className={error[2].status ? style['contact-input__error'] : style['contact-input']}
                type="text"
                name="phoneNumber"
                placeholder="Teléfono (opcional)"
                value={fields.phoneNumber}
                onChange={(e) => handleInputChange(e)}
                onBlur={(e) => checkInputErrors(e)}
                maxLength={20}
              />
            )}
            {error[2].status && <p className={style.error}>{error[2].msg}</p>}
          </div>
          <div className={`${style.accordion} ${style['show-display']}`}>
            <div className={style['content-accordion']}>
              <div className={`${style.checkArea} ${style.events} ${style['area-level-selection']}`}>
                {/* <h6>Niveles educativos de interés</h6> */}
                <span>Niveles educativos de interés</span>
                <div id="container-levels" className={style['container-input-label']}>
                  {/* <div className={style['label-container']}>
                    <input
                      className={style['contact-input']}
                      type="checkbox"
                      name="do_not_allow_infos"
                      onChange={(e) => handleInputChange(e, 'infos')}
                      checked={fields.do_not_allow_infos}
                    />
                    <span className={style['checkbox-span']}>
                      No deseo brindar esta información
                    </span>
                  </div> */}
                  <Chips
                    allChipsLevel={chips}
                    setChipsLevel={setChips}
                    doNotAllowInfos={fields.do_not_allow_infos}
                  />
                  <DropDownSelect
                    allChipsLevel={chips}
                    setChipsLevel={setChips}
                    schedules={schedules}
                    allowInfo={fields.do_not_allow_infos}
                    clearError={setError}
                  />
                </div>
                {error[4].status && <p className={style.error}>{error[4].msg}</p>}
              </div>
            </div>
          </div>
          {!!seasonOptions?.length && (
            <div className={`${style.accordion} ${style['show-display']}`}>
              <div className={style['content-accordion']}>
                <div className={`${style.checkArea} ${style.events} ${style['area-level-selection']}`}>
                  {/* <h6>Niveles educativos de interés</h6> */}
                  <span>Año de ingreso</span>
                  <div id="container-seasons" className={style['container-input-label']}>
                    <select
                      name="season"
                      className="dropDownSelectLevel"
                      onChange={(e) => handleInputChange(e)}
                    >
                      {!fields.season && (
                        <option value="" selected>
                          Seleccione
                        </option>
                      )}
                      {seasonOptions.map(({ id, label }) => (
                        <option key={label} value={id}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {error[6].status && <p className={style.error}>{error[6].msg}</p>}
                </div>
              </div>
            </div>
          )}
          <div className={style['message-container']}>
            {/* <span className={`${style['phone-span']} ${style['doble-span']}`}>
              Mensaje para el centro
            </span> */}
            <textarea
              id="message-area"
              name="messageToSchool"
              placeholder="Mensaje para el centro educativo"
              value={fields.messageToSchool}
              // className={style['form-control']}
              className={error[2].status ? style['contact-input__error'] : style['contact-input']}
              onChange={(e) => handleInputChange(e)}
              maxLength="300"
              rows="5"
              cols="10"
            />
            {error[5].status && <p className={style.error}>{error[5].msg}</p>}
          </div>
          <hr />
          <div className={`${style.checkArea} ${style['check-terms']} ${style['contact-container-check']}`}>
            <input
              style={{ marginLeft: error[3].status && '0px' }}
              className={`${style['terms-input']}`}
              type="checkbox"
              name="accept_the_terms"
              checked={fields.accept_the_terms}
              onChange={(e) => handleInputChange(e, 'radio')}
            />
            <span className={`${style.checkbox} ${style['checkbox-span']}`}>
              Estoy de acuerdo con los
              {' '}
              <a href="/terms-and-conditions" target="_blank">Términos</a>
              {' '}
              y las
              {' '}
              <a href="/privacy-policies" target="_blank">Políticas de privacidad</a>
              .
            </span>
          </div>
          {error[3].status && <p className={style.error}>{error[3].msg}</p>}
        </div>
        <button className={style['contact-btn']} type="button" onClick={(e) => handleFormSubmit(e)}>ENVIAR</button>
        {responseMsg && <p>{responseMsg}</p>}
      </form>
    </Modal>
  );
}

ContactModal.defaultProps = {
  modalAction: '',
  schoolId: 0,
  candidateSource: null,
};

ContactModal.propTypes = {
  schoolName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  modalAction: PropTypes.string,
  schoolId: PropTypes.number,
  candidateSource: PropTypes.number,
};

export default ContactModal;
