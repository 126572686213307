/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaChevronDown, FaUserCircle } from 'react-icons/fa';
import { RiLogoutCircleLine } from 'react-icons/ri';
import classNames from 'classnames';
import { useUserData } from 'hooks/UserContext';
import { useAppContext } from 'hooks/ApplicationContext';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import ClipLoader from 'react-spinners/ClipLoader';
import logoutFromApp from 'utils/User/userMethods';
// import { getUrl } from 'urlConfig';
import Searchbar from './headerComponents/Searchbar';
// import SlidebarMobile from './headerComponents/SlidebarMobile';
import ExperienceButton from '../../pages/Experience/src/components/button';
import Snackbar from '../../pages/Experience/src/components/snackbar';
import ExperienceSlideMenu from '../../pages/Experience/src/components/slide-menu';
import useResponsive from '../../pages/Experience/src/hooks/useResponsive';
// import ExperienceContext from '../../pages/Experience/src/context/experience-context';
import FlowContext from '../../pages/Experience/src/components/flow/context';
import signingLogo from '../../assets_vacanted/signin_logo.png';
import headerLogos from './headerComponents/Logos';
import { FamilyIcon, InstituteIcon } from './icons';
import SearchIcon from '../Icons/SearchIcon';
import CloseIcon from '../Icons/CloseIcon';
import LoginModal from '../LoginModal';

import './styles.scss';

const Header = ({
  defaultLoginStep, hideSlideMenu, fixed, showSearch, showUserMenu, title, onClose: onCloseParam,
}) => {
  /* const {
    stepQuantity: isFlow,
    onExit,
  } = useContext(ExperienceContext); */
  const {
    isFlow: isNewFlow,
    onExit,
  } = useContext(FlowContext);
  const isFlow = isNewFlow || !!onCloseParam;
  const onClose = onCloseParam || (isNewFlow && onExit && { title: 'Cerrar', action: onExit });
  const { isDesktop, searchbarFullWidth } = useResponsive();
  const { snackbarContext: { snackbar } } = useAppContext();
  const [showSlidebar, setShowSlidebar] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(!!defaultLoginStep);
  const [avatar, setAvatar] = useState(signingLogo);
  const toggleShowSlidebar = () => setShowSlidebar(!showSlidebar);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const {
    userData, isUserLoading, setUserData, isLoginModalOpen, setIsLoginModalOpen,
  } = useUserData();

  const navigate = useNavigate();
  const { loginStatus, data } = userData;

  useEffect(() => setAvatar(data.avatar || signingLogo), [loginStatus]);

  useEffect(() => {
    if (openLoginModal) {
      setIsLoginModalOpen(true);
      setOpenLoginModal(false);
    }
  }, [openLoginModal]);

  const renderLoginAvatar = () => {
    if (loginStatus && data) {
      return (
        <>
          <img src={avatar} className="avatar__image" alt="avatar" />
          <FaChevronDown className={`${isProfileMenuOpen ? 'singing-chevron--open' : 'singing-chevron'}`} />
        </>
      );
    }

    return (
      <>
        <FaUserCircle size={32} />
        <FaChevronDown className={`${isProfileMenuOpen ? 'singing-chevron--open' : 'singing-chevron'}`} />
      </>
    );
  };

  const userLogout = () => {
    const { token } = parsedDataInStorage('vacanted');

    if (token) logoutFromApp(setUserData, token);
  };

  const toggleLoginModal = () => {
    setIsLoginModalOpen((prevState) => !prevState);
    setIsProfileMenuOpen((prevState) => !prevState);
  };

  const hideLoginModal = () => {
    setIsLoginModalOpen(false);
    setIsProfileMenuOpen(false);
  };

  const redirectToSchoolLogin = () => navigate('/auth/school');

  const toggleSigningLogo = () => {
    setIsProfileMenuOpen((prevState) => !prevState);
  };

  const classNamesHeader = classNames('header-container', {
    'header-container--fixed': !!fixed,
  });

  return (
    <nav id="vacanted-header" className={classNamesHeader}>
      {!isFlow && !hideSlideMenu && !!userData.loginStatus && (
        <div className="slidebar-toggle">
          <button id="slidebar-toggle-btn" className="slidebar-btn" onClick={toggleShowSlidebar} type="button">
            <FaBars
              size={25}
              color="#3a3a3a"
            />
          </button>
        </div>
      )}
      {isDesktop && headerLogos.renderHeaderLogo()}
      {!isDesktop && headerLogos.renderHeaderMobileLogo()}
      {!!title && <div className="header-title">{title}</div>}
      <div className="rigth-actions">
        {!isFlow && !!showSearch && !isSearchVisible && (
          <ExperienceButton size="small" onClick={() => setIsSearchVisible(true)} className="search-button" iconOnly>
            <SearchIcon />
          </ExperienceButton>
        )}
        {!isFlow && !!showSearch && !searchbarFullWidth && (
          <div className={`searchbar-container ${isSearchVisible ? 'searchbar-container--visible' : ''}`}>
            <Searchbar shown={isSearchVisible} hideFunction={() => setIsSearchVisible(false)} />
          </div>
        )}
        {!isFlow && !!showSearch && !!searchbarFullWidth && (
          <div className={`searchbar-container searchbar-container--full-width ${isSearchVisible ? 'searchbar-container--visible' : ''}`}>
            <Searchbar shown={isSearchVisible} hideFunction={() => setIsSearchVisible(false)} />
          </div>
        )}
        {!isFlow && !!showUserMenu && (
          <>
            <ExperienceButton size="small" onClick={toggleSigningLogo} iconOnly={!!userData.loginStatus}>
              {!userData.loginStatus && 'Ingresar'}
              {!!userData.loginStatus && !!isUserLoading && (
                <ClipLoader color="#0096ed" loading={isUserLoading} size={40} />
              )}
              {!!userData.loginStatus && !isUserLoading && renderLoginAvatar()}
            </ExperienceButton>
            <div className={`${isProfileMenuOpen ? 'dropdown-content dropdown--open' : 'dropdown-content'}`}>
              {!userData.loginStatus ? (
                <>
                  <button
                    type="button"
                    className="dropdown__btn"
                    onClick={() => toggleLoginModal()}
                  >
                    <FamilyIcon className="type-login-icon" />
                    <p className="item-text">Entrar como Familia</p>
                  </button>
                  <button
                    type="button"
                    className="dropdown__btn"
                    onClick={() => redirectToSchoolLogin()}
                  >
                    <InstituteIcon className="type-login-icon" />
                    <p className="item-text">Entrar como Institución</p>
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="dropdown__btn"
                    onClick={() => userLogout()}
                  >
                    <RiLogoutCircleLine className="type-login-icon" size={20} />
                    <p className="item-text">Cerrar Sesión</p>
                  </button>
                </>
              )}
            </div>
          </>
        )}
        {!!onClose && (
          <ExperienceButton size="small" onClick={onClose.action} iconOnly={!!searchbarFullWidth}>
            <CloseIcon />
            {' '}
            <span>{onClose.title}</span>
          </ExperienceButton>
        )}
      </div>
      {/* {showUserMenu && !userData.loginStatus && (
        <ExperienceButton size="small" onClick={() => setShowSlidebar(true)}>
          Ingresar
        </ExperienceButton>
      )} */}
      {!hideSlideMenu && !!userData.loginStatus && (
        <ExperienceSlideMenu
          show={showSlidebar}
          setShow={setShowSlidebar}
          alwaysCollapsable
        />
      )}
      {isLoginModalOpen && (
        <LoginModal onClose={hideLoginModal} defaultLoginStep={defaultLoginStep} />
      )}
      <Snackbar {...snackbar} show={!!snackbar} />
    </nav>
  );
};

Header.propTypes = {
  defaultLoginStep: PropTypes.string,
  hideSlideMenu: PropTypes.bool,
  fixed: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  showSearch: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.shape({
    title: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

Header.defaultProps = {
  defaultLoginStep: null,
  hideSlideMenu: false,
  fixed: true,
  showUserMenu: true,
  showSearch: true,
  title: '',
  onClose: null,
};

export default Header;
