/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { useSearch } from 'hooks/SearchContext';
import localStorageVacantest from 'components/Vacantest/localStorageVacantest';
import VacantestDropdown from '../Dropdown/VacantestDropdown';
import rStyles from '../DefaultCardStyles.module.css';

const VacantesReligion = ({ card, toggleTips, disableNext }) => {
  const { locationInfo } = useSearch();
  const [religionOptions, setReligionOptions] = useState({ options: [], mutatedOptions: [] });
  const [dropdownController, setDropdownController] = useState({ active: 0, max: 3 });
  const [vacantestData, setVacantestData] = useState({
    religious: [{ id: 0, label: 'Laica', checked: false }, { id: 1, label: 'Religiosa', checked: false }],
    religions: [],
    indiferent: false,
  });
  const [loading, setLoading] = useState(true);
  const didMountRef = useRef(false);

  const updateReligionOptions = (options, selected) => {
    const mutatedOptions = options.filter((opt) => !selected
      .find((sRel) => sRel.id === opt.id));

    setReligionOptions({ options, mutatedOptions });
  };

  const getInitialData = async () => {
    if (!didMountRef.current) {
      toggleTips(false);
      const defaultValues = localStorageVacantest.get(card);
      await api.get(`religion/${locationInfo.countryId}`)
        .then((r) => r.data)
        .then((data) => {
          const options = data
            .filter((rel) => rel.name !== '')
            .sort((a, b) => a.name.localeCompare(b.name));

          const defaultReligions = (defaultValues && defaultValues.religions?.length)
            ? defaultValues.religions : [];

          updateReligionOptions(options, defaultReligions);
        });

      if (defaultValues) {
        setDropdownController({ ...dropdownController, active: defaultValues.religions?.length });

        setVacantestData(defaultValues);
        disableNext(false);
      } else disableNext(true);

      didMountRef.current = true;
      setLoading(false);
    }
  };
  useEffect(() => getInitialData(), []);

  const handleCheckboxToggle = (cb, checked) => {
    const parsedData = JSON.parse(cb);

    const newData = vacantestData.religious.map((r) => {
      if (checked) {
        if (r.id === parsedData.id) return { ...r, checked };
        return { ...r, checked: !checked };
      }

      return { ...r, checked };
    });
    setVacantestData({
      indiferent: checked ? false : vacantestData.indiferent,
      religious: newData,
      religions: [],
    });
  };

  const saveReligion = (rel, id) => {
    const aux = vacantestData.religions;
    aux[id - 1] = rel;
    setVacantestData((data) => ({ ...data, religions: [...aux] }));
  };

  const handleMainCheckboxToggle = (e) => {
    const mutatedReligious = vacantestData.religious.map((vr) => ({
      ...vr, checked: false,
    }));

    setVacantestData({
      religions: [], religious: mutatedReligious, indiferent: e.target.checked,
    });
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (vacantestData.religious[1].checked) {
        if (vacantestData.religions.length) {
          setDropdownController({ ...dropdownController, active: vacantestData.religions.length });
        } else setDropdownController({ ...dropdownController, active: 1 });
      } else setDropdownController({ ...dropdownController, active: 0 });
    }
  }, [vacantestData.religious[1].checked]);

  useEffect(() => {
    if (didMountRef.current) {
      if (vacantestData.religions.length) {
        updateReligionOptions(religionOptions.options, vacantestData.religions);
      }
    }
  }, [vacantestData.religions]);

  const isCardDataValid = () => {
    const { religions, religious, indiferent } = vacantestData;
    const religionsStatus = religions.length;
    const religiousStatus = religious[0].checked;

    return [religiousStatus, religionsStatus, indiferent].some((status) => status);
  };

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageVacantest.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (vacantestData.religious[1].checked) toggleTips(true);
    else toggleTips(false);

    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(vacantestData);

    if (!vacantestData.religious[1].checked) {
      setReligionOptions((opt) => ({ ...opt, mutatedOptions: opt.options }));
    }
  }, [vacantestData]);

  const checkDropdownOption = (id) => {
    if (vacantestData.religions.length) {
      if (vacantestData.religions[id - 1]) return vacantestData.religions[id - 1];
    }
    return { name: 'Agrega religión' };
  };

  const addReligionDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const removeReligionDropdown = (id) => {
    const aux = vacantestData.religions;
    aux.splice(id - 1, 1);

    setVacantestData((data) => ({ ...data, religions: [...aux] }));

    if (dropdownController.active > 1) {
      setDropdownController({ ...dropdownController, active: dropdownController.active - 1 });
    }
  };

  const renderPlusConditions = (id) => vacantestData.religions.length
    && vacantestData.religions[id - 1]
    && id < dropdownController.max;

  const renderMinusConditions = (id) => vacantestData.religions.length
    && vacantestData.religions[id - 1]
    && id > 1;

  const renderDropdowns = () => {
    const dropdonwsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdonwsArray.push(i);
    }

    return dropdonwsArray.map((id) => (
      <div key={id}>
        <VacantestDropdown
          option={checkDropdownOption(id)}
          options={religionOptions.mutatedOptions}
          setOption={(e) => saveReligion(e, id)}
        />
        <div className={rStyles.vacantest__icon__holder}>
          {renderPlusConditions(id) ? (
            <button
              type="button"
              className={rStyles.vacantest__plus__icon}
              onClick={() => addReligionDropdown(id)}
            >
              <AiOutlinePlus size={20} color="#333" />
            </button>
          ) : null}
          {renderMinusConditions(id) ? (
            <button
              type="button"
              className={rStyles.vacantest__plus__icon}
              onClick={() => removeReligionDropdown(id)}
            >
              <AiOutlineMinus size={20} color="#333" />
            </button>
          ) : null}
        </div>
      </div>
    ));
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>Religión</h3>
          <p>
            Desde el punto de vista religioso, deseas que sea una institución educativa...
          </p>
          <div className={rStyles.vacantest__section__container}>
            {vacantestData.religious.map((rel) => (
              <label
                htmlFor={rel.id}
                className={rStyles.vacantest__checkbox__container}
                key={rel.id}
              >
                <input
                  type="checkbox"
                  className={rStyles.vacantest__checkbox}
                  id={rel.id}
                  // disabled={vacantestData.indiferent}
                  checked={vacantestData.religious[rel.id].checked || false}
                  onChange={(e) => handleCheckboxToggle(JSON.stringify(rel), e.target.checked)}
                />
                <p className={rStyles.vacantest__checkbox__label}>{rel.label}</p>
              </label>
            ))}
          </div>
          {dropdownController.active > 0 && renderDropdowns()}
          <hr />
          <div className={rStyles.vacantest__section__container}>
            <h3>¿O te es indiferente?</h3>
            <label className={rStyles.vacantest__checkbox__container} htmlFor="indiferent">
              <input
                type="checkbox"
                className={rStyles.vacantest__checkbox}
                id="indiferent"
                checked={vacantestData.indiferent || false}
                onChange={(e) => handleMainCheckboxToggle(e)}
              />
              <p className={rStyles.vacantest__checkbox__label}>Si, me es indiferente</p>
            </label>
          </div>
        </>
      ) : (
        <p className={rStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantesReligion;

VacantesReligion.propTypes = {
  card: PropTypes.number.isRequired,
  toggleTips: PropTypes.func.isRequired,
  disableNext: PropTypes.func.isRequired,
};
