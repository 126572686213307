import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { useUserData } from 'hooks/UserContext';
import saveVacantest, { validateVacantest, dataToSave } from 'utils/Favorites/saveVacantest';
import ClipLoader from 'react-spinners/ClipLoader';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import { getUrl, isAnonymusVacantedAllowed } from 'urlConfig';
import countryService from 'services/countryService';
import sessionStorageServices from 'services/sessionStorageServices';
import mStyles from '../DefaultCardStyles.module.css';
import specificStyles from './specific.module.scss';
import regex from '../../../../utils/Regex/regex';

const VacantestEnd = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [singleEmail, setSingleEmail] = useState('');
  const [statusEmail, setStatusEmail] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { userData, setIsLoginModalOpen } = useUserData();
  const { loginStatus } = userData;
  const navigate = useNavigate();
  const appURL = getUrl('app');

  const saveVacantestInDB = async (vacantest, countryId) => {
    const date = Date().toString();

    const data = {
      type: 'vacantest',
      name: `${singleEmail} - ${date}`,
      country_id: countryId,
      context: vacantest,
      results: null,
      savedAt: date,
    };

    return api.post('/collection/vacantest', data)
      .then((r) => r)
      .catch((e) => e);
  };

  const saveSingleVacantest = async () => {
    const validEmail = regex.emailRegex.test(singleEmail);

    if (!validEmail) {
      setSendingEmail(false);
      return setStatusEmail(false);
    }

    setStatusEmail(true);
    setSendingEmail(true);
    const vacantestFromLS = parsedDataInStorage('vacantest');

    const validTest = validateVacantest(vacantestFromLS);
    if (!validTest) return false;

    const data = dataToSave(vacantestFromLS);
    const countryId = countryService.getCurrentCountryId();

    const response = await saveVacantestInDB(data, countryId);

    if (response?.status === 201) {
      const body = {
        email: singleEmail,
        baseURL: appURL,
      };

      const sendEmailSingleVacantest = await api.post('/collection/sendvacantest', body);

      if (sendEmailSingleVacantest?.status === 200) {
        navigate('/');
      }

      return { message: 'Error sending email' };
    }

    return { message: 'Error saving vacantest' };
  };

  const saveVacantestAttempt = (_destination) => {
    if (!loginStatus) {
      const vacantestFromLS = parsedDataInStorage('vacantest');
      sessionStorageServices.saveItem('temp_save', {
        vacantest: vacantestFromLS,
        type: 'vacantest',
      });
      sessionStorageServices.saveItem('userFrom', 'vacantest');
      setIsLoginModalOpen(true);
    } else {
      saveVacantest(setIsLoading);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    const trimmedEmail = value.trim();

    setSingleEmail(trimmedEmail);
  };

  return (
    <div className={mStyles.vacantest__card__content}>
      <h3>¡Excelente! Completaste tu Test de Compatibilidad</h3>
      <p>
        De acuerdo a la información que nos has compartido,
        nuestros algoritmos han asignado un grado de afinidad a cada institución educativa,
        resultando en una lista de centros recomendados a la medida de sus intereses,
        que tendrán siempre disponible en su área privada de familia. Para acceder a los
        resultados de tu Test, inicia sesión o regístrate si aún no tienes tu cuenta gratuita
      </p>
      {isLoading ? (
        <div className={specificStyles.loader__container}>
          <ClipLoader color="#0096ed" loading={isLoading} size={35} />
        </div>
      ) : (
        <button
          type="button"
          className={specificStyles.cta_btn}
          onClick={() => saveVacantestAttempt('login')}
        >
          {loginStatus ? '' : 'YA TENGO CUENTA - '}
          VER RESULTADOS
        </button>
      )}
      {!loginStatus && (
        <>
          <hr />
          <div>
            <div className={specificStyles.sign_up_box}>
              <div className={specificStyles.signUpBox__title}>
                <h4>¿Aún no tienes una cuenta? </h4>
              </div>
              <div className={specificStyles.sign_up_text}>
                <p>
                  Regístrate para acceder a tu área privada gratuita
                  y así poder guardar y gestionar tus resultados en todo momento.
                </p>
              </div>
              <div className={specificStyles.button_box}>
                <button
                  className={specificStyles.sign_up_btn}
                  type="button"
                  onClick={() => saveVacantestAttempt('register')}
                >
                  REGISTRARME
                </button>
              </div>
            </div>
            {/* ToDo: ADD NAME to register */isAnonymusVacantedAllowed() && (
              <div className={specificStyles.send_mail}>
                <div className={specificStyles.send_mail_text}>
                  <h4>No quiero crear mi cuenta todavía. </h4>
                  <p>
                    Quiero que me envíen los resultados a mi email.
                  </p>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="email"
                    className={specificStyles.input_mail}
                    onChange={handleInput}
                  />
                  <button
                    className={specificStyles.send_mail_btn}
                    type="button"
                    onClick={() => saveSingleVacantest()}
                    disabled={sendingEmail}
                  >
                    ENVIAR
                  </button>
                  {!statusEmail
                    && !sendingEmail
                    && <p className={specificStyles.invalid__input}>Ingresa un email válido</p>}
                  {sendingEmail
                    && statusEmail
                    && <p>Los resultados fueron enviados a su e-mail.</p>}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VacantestEnd;
