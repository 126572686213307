import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckSelector from './CheckSelector';
import CounterSelector from './CounterSelector';
import TextAreaSelector from './TextAreaSelector';

const resolveCheckSelectedDefault = (opts) => {
  let checkSelectedDefault = {};
  opts?.forEach(({
    value, checked, counter, text,
  }) => {
    checkSelectedDefault = {
      ...checkSelectedDefault,
      [value]: {
        checked,
        count: counter?.count,
        text,
      },
    };
  });
  return checkSelectedDefault;
};

const ListCheckSelector = ({
  id, options, onChange, maxOptions,
}) => {
  const withCounter = options?.some((option) => !!option.counter);
  const withOther = options?.some((option) => !!option.otherOptionData);

  const [checkOptionsSelected, setCheckOptionsSelected] = useState({});

  useEffect(() => {
    setCheckOptionsSelected(resolveCheckSelectedDefault(options));
  }, [id]);

  const valueMapper = (values) => {
    const valueMapped = [];
    Object.keys(values).forEach((key) => {
      if (checkOptionsSelected[key].checked) {
        if (withCounter || withOther) {
          valueMapped.push({
            value: key,
            count: withCounter ? checkOptionsSelected[key].count : null,
            text: withOther ? checkOptionsSelected[key].text : null,
          });
        } else {
          valueMapped.push(key);
        }
      }
    });
    return valueMapped.length ? valueMapped : null;
  };

  const maxOptionsReached = () => maxOptions && Object.values(checkOptionsSelected)
    .filter(({ checked }) => !!checked).length >= maxOptions;

  const onCheckChangeHandler = (event) => {
    if (event.target.checked && maxOptionsReached()) {
      return;
    }
    const newState = { ...checkOptionsSelected };
    newState[event.target.value].checked = event.target.checked;
    setCheckOptionsSelected(newState);

    if (onChange) {
      const newValue = valueMapper(checkOptionsSelected);
      onChange(newValue);
    }
  };

  const onCountChangeHandler = (value, newCount) => {
    const newState = { ...checkOptionsSelected };
    newState[value].count = newCount;
    setCheckOptionsSelected(newState);

    if (onChange) {
      const newValue = valueMapper(checkOptionsSelected);
      onChange(newValue);
    }
  };

  const onOtherTextChangeHandler = (newText) => {
    const newState = { ...checkOptionsSelected };
    newState.OTHER.text = newText;
    setCheckOptionsSelected(newState);

    if (onChange) {
      const newValue = valueMapper(checkOptionsSelected);
      onChange(newValue);
    }
  };

  return options?.map((option) => {
    const {
      name, label, value, counter, disabled, otherOptionData, checked,
    } = option;
    if (!checkOptionsSelected[value]) return null;
    return (
      <React.Fragment key={value}>
        <CheckSelector
          name={name}
          label={label}
          checked={checked}
          value={value}
          onChange={onCheckChangeHandler}
          counter={counter}
          disabled={disabled || (!checkOptionsSelected[value].checked && maxOptionsReached())}
        >
          {(
            !!counter
              && checkOptionsSelected[value]?.count
              && checkOptionsSelected[value]?.checked
          ) && (
            <CounterSelector
              count={checkOptionsSelected[value].count}
              label={counter.label}
              onChange={(newCount) => onCountChangeHandler(value, newCount)}
            />
          )}
        </CheckSelector>
        {otherOptionData && checkOptionsSelected[value]?.checked && (
          <TextAreaSelector
            placeholder={otherOptionData.placeholder}
            value={checkOptionsSelected[value].text ?? ''}
            onChange={onOtherTextChangeHandler}
          />
        )}
      </React.Fragment>
    );
  });
};

ListCheckSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
      checked: PropTypes.bool,
      counter: PropTypes.shape({
        label: PropTypes.string,
        count: PropTypes.number,
      }),
    }),
  ).isRequired,
  maxOptions: PropTypes.number,
};

ListCheckSelector.defaultValues = {
  maxOptions: 0,
};

export default ListCheckSelector;
