/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { useSearch } from 'hooks/SearchContext';
import localStorageVacantest from 'components/Vacantest/localStorageVacantest';
import rStyles from '../DefaultCardStyles.module.css';
import radioStyles from '../Checkboxes/Checkboxes.module.css';
import specificStyles from './specific_styles.module.scss';

const VacantestLanguage = ({ card, disableNext }) => {
  const { locationInfo } = useSearch();
  const [dropdownController, setDropdownController] = useState({ active: 1, max: 4 });
  const [languageOptions, setLanguageOptions] = useState({ options: [], mutatedOptions: [] });
  const [vacantestData, setVacantestData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dropdownStatusArr = [];
  let dropIndex = 0;
  while (dropIndex < dropdownController.max) {
    dropdownStatusArr.push({ active: false });
    dropIndex += 1;
  }

  const [isDropdownActive, setIsDropdownActive] = useState(dropdownStatusArr);
  const didMountRef = useRef(false);

  const updateLanguageOptions = (options, selected) => {
    const mutatedOptions = options.filter((opt) => !selected
      .find((sLan) => sLan.id === opt.id));

    setLanguageOptions(() => ({ options, mutatedOptions }));
  };

  const getInitialData = async () => {
    if (!didMountRef.current) {
      const defaultValues = localStorageVacantest.get(card);
      await api.get(`language/${locationInfo.countryId}`)
        .then((r) => r.data)
        .then((data) => {
          const options = data
            .filter((lan) => lan.name !== '')
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((acc, option) => ([
              ...acc, { ...option, priority: { status: false, value: '' } },
            ]), []);

          const defaultLanguages = () => {
            if (defaultValues?.length > 0) return defaultValues;
            return [];
          };
          updateLanguageOptions(options, defaultLanguages());
        });

      if (defaultValues?.length > 0) {
        setDropdownController({
          ...dropdownController, active: defaultValues.length,
        });
        setVacantestData(defaultValues);
        disableNext(false);
      } else disableNext(true);

      didMountRef.current = true;
      setLoading(false);
    }
  };

  useEffect(() => getInitialData(), []);

  const isCardDataValid = () => {
    let isValid = false;
    const lastLanguage = vacantestData[vacantestData.length - 1];

    if (lastLanguage) {
      isValid = lastLanguage?.priority.status && lastLanguage?.id;
    }

    return isValid;
  };

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    localStorageVacantest.save(card, data);
    disableNext(false);
  };

  useEffect(() => {
    if (!isCardDataValid()) removeCardFromLocalStorage();
    else saveCardInLocalStorage(vacantestData);

    updateLanguageOptions(languageOptions.options, vacantestData);
  }, [vacantestData]);

  const handleDropdownSelect = (language, key) => {
    let updatedLanguage = language;
    const hasPriority = vacantestData[key]?.priority.status;
    if (hasPriority) {
      const languagePriority = vacantestData[key]?.priority;
      updatedLanguage = { ...updatedLanguage, priority: languagePriority };
    }

    const updatedData = vacantestData;
    updatedData[key] = updatedLanguage;
    setVacantestData([...updatedData]);

    setIsDropdownActive(() => isDropdownActive.reduce((acc) => ([...acc, { active: false }]), []));
  };

  const handlePriority = (value, key) => {
    const thisLanguage = vacantestData[key] || {};
    const updatedLanguage = { ...thisLanguage, priority: { status: true, value } };
    const updatedData = vacantestData;
    updatedData[key] = updatedLanguage;
    setVacantestData([...updatedData]);
  };

  const getLanguageNameToShow = (key) => {
    const placeholder = key > 0 ? 'Agrega otra lengua' : 'Agrega primera lengua';
    return vacantestData[key]?.name || placeholder;
  };

  const checkRadioValue = (key, type) => vacantestData[key]?.priority.value === type;

  const removeLanguagesDropdown = () => {
    const activeQuantity = dropdownController.active;
    if (dropdownController.active > 1) {
      setDropdownController({ ...dropdownController, active: activeQuantity - 1 });
    }

    if (activeQuantity === vacantestData.length) {
      const updatedData = vacantestData;
      updatedData.pop();
      setVacantestData([...updatedData]);
    }
  };

  const addLanguagesDropdown = () => {
    if (dropdownController.active < dropdownController.max) {
      setDropdownController({ ...dropdownController, active: dropdownController.active + 1 });
    }
  };

  const checkConditionsForMinusBtn = () => dropdownController.active > 1;

  const checkConditionsForPlusBtn = () => {
    const lastIndex = vacantestData.length - 1;
    const isLastLanValid = vacantestData[lastIndex]?.id
      && vacantestData[lastIndex]?.priority.status
      && lastIndex + 1 < dropdownController.max
      && lastIndex + 1 === dropdownController.active;

    return isLastLanValid;
  };

  function checkActiveDropdownValue(index, key) {
    const currentValue = isDropdownActive[key]?.active;
    if (currentValue) return false;
    return index === key;
  }

  const checkboxesOptions = [
    { id: 0, name: 'Baja' },
    { id: 1, name: 'Media' },
    { id: 2, name: 'Alta' },
  ];

  const renderDropdowns = () => {
    const dropdownsArray = [];

    for (let i = 1; i <= dropdownController.active; i += 1) {
      dropdownsArray.push({ id: i - 1 });
    }

    return (
      dropdownsArray.map((drop) => (
        <div key={drop.id}>
          <div
            style={{
              // backgroundColor: 'tomato',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div className={specificStyles.dropdown}>
              <button
                type="button"
                className={specificStyles.dropdown__button}
                onClick={() => {
                  const updatedDropdowns = isDropdownActive.reduce((acc, _e, index) => ([
                    ...acc,
                    { active: checkActiveDropdownValue(index, drop.id) },
                  ]), []);
                  setIsDropdownActive(updatedDropdowns);
                }}
                style={{ marginBottom: (dropdownController.active === drop.id + 1) ? '0' : '25px' }}
              >
                <p>{getLanguageNameToShow(drop.id)}</p>
                <div className={specificStyles.dropdown__icon}>
                  <BsChevronDown size={20} />
                </div>
              </button>
              {isDropdownActive[drop.id]?.active && (
                <div className={specificStyles.dropdown__content}>
                  {languageOptions.mutatedOptions.map((item) => (
                    <button
                      type="button"
                      className={specificStyles.dropdown__item}
                      onClick={() => handleDropdownSelect(item, drop.id)}
                      key={item.id}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className={specificStyles.vacantest__checkboxes}>
              {checkboxesOptions.map((option) => (
                <div
                  className={radioStyles.vacantest__checkbox__individual__container}
                  key={option.id}
                >
                  <input
                    className={radioStyles.vacantest__checkbox}
                    type="radio"
                    name={drop.id}
                    onChange={() => handlePriority(option.name, drop.id)}
                    value={option.name}
                    checked={checkRadioValue(drop.id, option.name)}
                    style={{ marginBottom: (dropdownController.active === drop.id + 1) ? '0' : '25px' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className={rStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>Idiomas</h3>
          <p>
            Identifica las lenguas (idiomas) que deseas que la institución brinde y su grado de importancia para la educación de tus hijos.
          </p>
          <div className={specificStyles.vacantest__checkboxes__row}>
            <div className={specificStyles.checkboxes__holder} />
            <div className={specificStyles.vacantest__checkboxes}>
              {checkboxesOptions.map((option) => (
                <div
                  className={specificStyles.vacantest__individual__checkbox__title}
                  key={option.id}
                >
                  <h3>{option.name}</h3>
                </div>
              ))}
            </div>
          </div>
          {dropdownController.active > 0 && renderDropdowns()}
          <div className={rStyles.vacantest__icon__holder}>
            {checkConditionsForPlusBtn() && (
              <button
                type="button"
                className={rStyles.vacantest__plus__icon}
                onClick={() => addLanguagesDropdown()}
              >
                <AiOutlinePlus size={20} color="#333" />
              </button>
            )}
            {checkConditionsForMinusBtn() && (
              <button
                type="button"
                className={rStyles.vacantest__plus__icon}
                onClick={() => removeLanguagesDropdown()}
              >
                <AiOutlineMinus size={20} color="#333" />
              </button>
            )}
          </div>
        </>
      ) : (
        <p className={rStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestLanguage;

VacantestLanguage.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
