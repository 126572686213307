/* const formatLevelName = (name) => {
  let age = name.slice(0, name.indexOf(' '));
  age = Number(age);

  switch (true) {
    case age <= 2:
      if (age === 1) {
        return 'Jardín maternal - 1 año';
      }
      return `Jardín maternal - ${age} años`;
    case age === 3:
      return 'Jardín pre inicial - 3 años';
    case age === 4 || age === 5:
      return `Jardín inicial - ${age} años`;
    case age >= 6 && age <= 11:
      return `Primaria - ${age} años`;
    case age >= 12 && age <= 17:
      return `Secundaria - ${age} años`;
    default:
      return age;
  }
}; */

const levelNamesIndexedByAge = [
  'Jardín maternal - Hasta 12 meses',
  'Jardín maternal - 1 año',
  'Jardín maternal - 2 años',
  'Jardín pre inicial - 3 años',
  'Jardín inicial - 4 años',
  'Jardín inicial - 5 años',
  'Primaria - 1er año',
  'Primaria - 2do año',
  'Primaria - 3er año',
  'Primaria - 4to año',
  'Primaria - 5to año',
  'Primaria - 6to año',
  'Secundaria - 1er año',
  'Secundaria - 2do año',
  'Secundaria - 3er año',
  'Secundaria - 4to año',
  'Secundaria - 5to año',
  'Secundaria - 6to año',
];

const formatLevelName = (name) => {
  const ageNumber = Number(name.slice(0, name.indexOf(' ')));

  return levelNamesIndexedByAge[ageNumber];
};

const mutateLevelNames = (levelsData) => {
  const levelsWithFormattedNames = levelsData
    .reduce((acc, elem) => [
      ...acc,
      { ...elem, age: elem.name, name: formatLevelName(elem.name) },
    ], []);

  return levelsWithFormattedNames;
};

const mutateLevelOptions = (levels, setLevels, selectedLevels) => {
  const filteredLevels = levels.options
    .filter((l) => !selectedLevels.find((sl) => l.id === sl.id));

  setLevels({ ...levels, mutatedOptions: filteredLevels });
};

const checkMinMaxChildren = (n, op) => {
  let num = n;
  if (op === '+' && n < 9) num += 1;
  if (op === '-' && n > 1) num -= 1;

  return num;
};

const checkBtnAvailability = (vacantestData, id, op) => {
  if (!vacantestData[id - 1]) return !vacantestData[id - 1];
  if (op === '+') return !(vacantestData[id - 1].qty < 9);
  return !(vacantestData[id - 1].qty > 1);
};

const checkOptionName = (vacantestData, id) => vacantestData[id - 1]
  || { name: 'Selecciona el nivel educativo' };

const checkOptionQuantity = (vacantestData, id) => vacantestData[id - 1]?.qty || 0;

const checkNextAvailability = (vacantestData, dropdownController) => vacantestData.length
  && dropdownController.active === vacantestData.length;

export default {
  mutateLevelNames,
  mutateLevelOptions,
  checkMinMaxChildren,
  checkBtnAvailability,
  checkOptionName,
  checkOptionQuantity,
  checkNextAvailability,
};
