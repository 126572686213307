/* eslint-disable new-cap */
import React, { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import App from './App';
import store from './hooks/SchoolProfileContext/store';

const env = process.env.REACT_APP_VACANTED_ENV;

try {
  Sentry.init({
    enabled: env && !(/local/.test(env)),
    environment: env,
    dsn: 'https://6addc020ca0f40269166f9e836e4526f@o4505411377692672.ingest.sentry.io/4505411379527680',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // tracePropagationTargets: ['localhost', /^https:\/\/vacanted\.com\.\\w\\w\/api/],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100%
    // while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change
    // the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
// eslint-disable-next-line no-empty
} catch (_err) { }

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
