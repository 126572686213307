/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Select from 'react-select';
import { useSearch } from 'hooks/SearchContext';
import { useUserData } from 'hooks/UserContext';
import React, { useState } from 'react';
import Modal from 'react-modal';
import api from 'services/api';
import { getUrl } from 'urlConfig';
import closeIcon from '../../assets_vacanted/close.svg';
import styles from './styles.module.css';

Modal.setAppElement('#root');

function SchoolFormModal() {
  const [schoolsOptions, setSchoolsOptions] = useState();
  const [schools, setSchools] = useState();
  const [error, setError] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [schoolPhone, setSchoolPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [school, setSchool] = useState();
  const [city, setCity] = useState('');
  const appURL = getUrl('app');

  const {
    schoolRegisterModal,
    setSchoolRegisterModal,
  } = useUserData();

  const { locationInfo, cities } = useSearch();

  function citiesOptions() {
    return cities.map((item) => ({ value: item.id, label: item.name }));
  }

  const handleCityChange = async (e) => {
    setCity(e);
    const getSchools = await api.get(`/school/searchcity/${e.value}`);
    const gotSchools = getSchools.data.schools;
    setSchools(gotSchools);
    const getSchoolsOptions = gotSchools.map((item) => ({ value: item.id, label: item.name }));
    setSchoolsOptions(getSchoolsOptions);
  };

  const handleCloseModal = () => {
    setSchoolRegisterModal(false);
  };

  const handleSelectSchool = (e) => {
    const findSchool = schools.filter((item) => (
      item.id === e.value
    ));
    setAddress(findSchool[0].address);
    setSchool(e);
  };

  function hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  const handleSendForm = async () => {
    if (!password || !email || !adminName || !school || !adminPhone || !schoolPhone) {
      setError('Rellena todos los campos');
      return;
    }
    if (hasWhiteSpace(password)) {
      setError('No se permiten espacios en la contraseña');
      return;
    }
    const data = {
      name: adminName,
      role_id: 4,
      school_id: school.value,
      personal_phone: adminPhone,
      email,
      password,
      active: 0,
    };

    await api.post('/user/registry', data);
    setTimeout(() => {
      window.location.replace(appURL);
    }, 1000);
  };

  return (
    <Modal
      isOpen={schoolRegisterModal}
      onRequestClose={handleCloseModal}
      overlayClassName={styles.react_modal_overlay}
      className={styles.react_modal_content}
    >
      <div className={styles.header_keep}>
        <div className={styles.header_close}>
          <button
            type="button"
            onClick={handleCloseModal}
            className={styles.modal_close}
          >
            <img src={closeIcon} alt="Cerrar modal" />
          </button>
        </div>
        <h2>Área Privada Institución Educativa</h2>
        <p>
          Active su usuario administrador y solicite asignación a la institución educativa.
          Complete los datos y nos pondremos en contacto con usted para comenzar
          el proceso de validación y asignación de la Institución Educativa
        </p>

      </div>
      <form>
        <p>Nombre del administrador de la Institución</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Nombre completo"
          onChange={(e) => setAdminName(e.target.value)}
        />
        <p>País</p>
        <input
          className={styles.input}
          type="text"
          placeholder={locationInfo.countryName}
          readOnly
        />

        <p>Ciudad</p>
        <Select
          className={styles.select}
          options={citiesOptions()}
          value={city}
          placeholder="Seleccione la ciudad"
          onChange={(e) => handleCityChange(e)}
        />

        <div className={styles.schoolsContainer}>
          <p>Nombre de la Institución Educativa que representa</p>
          <Select
            className={styles.select}
            options={schoolsOptions}
            value={school}
            placeholder="Nombre de la Institución"
            onChange={(e) => handleSelectSchool(e)}
          />
          <p>Su Institución no está en la lista? Enviando un email a info.centros@vacanted.com</p>
        </div>

        <p>Dirección de la Insitución Educativa que Representa</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Dirección de la Institución"
          value={address}
          readOnly
        />

        <p>Teléfono Móvil de contacto</p>
        <input
          className={styles.input}
          type="tel"
          placeholder="Teléfono"
          onChange={(e) => setAdminPhone(e.target.value)}
        />

        <p>Teléfono de la Institución</p>
        <input
          className={styles.input}
          type="tel"
          placeholder="Teléfono"
          onChange={(e) => setSchoolPhone(e.target.value)}
          value={schoolPhone}
        />

        <p>eMail</p>
        <input
          className={styles.input}
          type="text"
          placeholder="Ingrese su email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <p>Clave</p>
        <input
          className={styles.input}
          type="password"
          placeholder="Ingrese su contraseña"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <p>
          {' '}
          Debe contener como mínimo: 08 (ocho) caracteres, 1 (una) mayúscula, 1 (un)
          número, 1 (un) caracter especial.
        </p>
        <p className={styles.error}>{error}</p>
        <button type="button" className={styles.keep_button} onClick={handleSendForm}>ENVIAR SOLICITUD</button>

      </form>
    </Modal>
  );
}

export default SchoolFormModal;
