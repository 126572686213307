import React from 'react';
import tipsIcon from 'assets_vacanted/icons/tips_icon.png';
import vStyles from '../../Vacantest.module.css';

const tips = [
  {
    id: 0,
    firstInfo: 'Área Privada Familia',
    firstContent: 'Deberás activar tu área privada en forma gratuita para poder gestionar los resultados de todos tus Tests de Compatibilidad y acceder a tu lista de instituciones educativas favoritas y mucho más.',
    secondInfo: 'Tus datos son privados',
    secondContent: 'En VACANTED™ cuidamos tu privacidad. Utilizamos unos estrictos estándares en relación con la política de privacidad de nuestros usuarios.',
  },
];

const VacantestEndTips = () => (
  tips.map((tip) => (
    <div className={vStyles.vacantest__tip} key={tip.id}>
      <div className={vStyles.vacantest__tip__icon}>
        <img src={tipsIcon} alt="tips" />
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.firstInfo}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.firstContent}</p>
      </div>
      <div className={vStyles.vacantest__tip__title}>
        <p>{tip.secondInfo}</p>
      </div>
      <div className={vStyles.vacantest__tip__content}>
        <p>{tip.secondContent}</p>
      </div>
    </div>
  ))
);

export default VacantestEndTips;
