/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiChevronLeft } from 'react-icons/ti';
import { CgClose } from 'react-icons/cg';

// import { registerAnalytics } from 'Authentication/Data/Services/firebase';

// import vacantedLogo from 'assets_vacanted/logo_v.png';
import { Helmet } from 'react-helmet';
import { useUserData } from 'hooks/UserContext';
import LoginModal from '../LoginModal';
import Header from '../Header/Header';

import localStorageVacantest from './localStorageVacantest';
import VacantestLocation from './VacantestCards/VacantestLocation/VacantestLocation';
import VacantestLocationTips from './VacantestCards/VacantestLocation/VacantestLocationTips';
import VacantestRange from './VacantestCards/VacantestRange/VacantestRange';
import VacantestManagement from './VacantestCards/VacantestManagement/VacantestManagement';
import VacantestReligion from './VacantestCards/VacantestReligion/VacantestReligion';
import VacantestReligionTips from './VacantestCards/VacantestReligion/VacantestReligionTips';
import VacantestCosts from './VacantestCards/VacantestCosts/VacantestCosts';
import VacantestCostsTips from './VacantestCards/VacantestCosts/VacantestCostsTips';
import VacantestEducationalLevels from './VacantestCards/VacantestEducationalLevels/VacantestEducationalLevels';
import VacantestStartDate from './VacantestCards/VacantestStartDate/VacantestStartDate';
import VacantestStartDateTips from './VacantestCards/VacantestStartDate/VacantestStartDateTips';
import VacantestEnd from './VacantestCards/VacantestEnd/VacantestEnd';
import VacantestEndTips from './VacantestCards/VacantestEnd/VacantestEndTips';
import VacantestLanguage from './VacantestCards/VacantestLanguages/VacantestLanguage';
import VacantestLanguageTips from './VacantestCards/VacantestLanguages/VacantestLanguageTips';
import VacantestMainFactors from './VacantestCards/VacantestMainFactors/VacantestMainFactors';
import VacantestmainFactorsTips from './VacantestCards/VacantestMainFactors/VacantestMainFactorsTips';

import vStyles from './Vacantest.module.css';

const Vacantest = () => {
  const [card, setCard] = useState(0);
  const [barPercentage, setBarPercentage] = useState();
  const [showDynamicTips, toggleShowDynamicTips] = useState(true);
  const [closeTestModal, setCloseTestModal] = useState(false);
  const [disabledBtn, toggleDisabledBtn] = useState(false);
  const [onlyPreschoolSelected, setOnlyPreschoolSelected] = useState(false);
  const navigate = useNavigate();

  const {
    isLoginModalOpen, setIsLoginModalOpen,
  } = useUserData();

  const cardsArray = [
    {
      content: <VacantestLocation card={card} disableNext={toggleDisabledBtn} />,
      tips: <VacantestLocationTips />,
      percentage: 10,
    },
    {
      content: <VacantestRange card={card} disableNext={toggleDisabledBtn} />,
      tips: null,
      percentage: 20,
    },
    {
      content: <VacantestManagement card={card} disableNext={toggleDisabledBtn} />,
      tips: null,
      percentage: 30,
    },
    {
      content: (
        <VacantestReligion
          card={card}
          toggleTips={toggleShowDynamicTips}
          disableNext={toggleDisabledBtn}
        />
      ),
      tips: <VacantestReligionTips />,
      percentage: 40,
    },
    {
      content: <VacantestEducationalLevels
        card={card}
        disableNext={toggleDisabledBtn}
        setOnlyPreschoolSelected={setOnlyPreschoolSelected}
      />,
      tips: null,
      percentage: 50,
    },
    {
      content: <VacantestLanguage
        card={card}
        disableNext={toggleDisabledBtn}
      />,
      tips: <VacantestLanguageTips />,
      percentage: 60,
    },
    {
      content: <VacantestMainFactors card={card} disableNext={toggleDisabledBtn} />,
      tips: <VacantestmainFactorsTips />,
      percentage: 70,
    },
    {
      content: <VacantestCosts
        card={card}
        disableNext={toggleDisabledBtn}
        onlyPreschoolSelected={onlyPreschoolSelected}
      />,
      tips: <VacantestCostsTips />,
      percentage: 80,
    },
    {
      content: <VacantestStartDate card={card} disableNext={toggleDisabledBtn} />,
      tips: <VacantestStartDateTips />,
      percentage: 90,
    },
    {
      content: <VacantestEnd card={card} disableNext={toggleDisabledBtn} />,
      tips: <VacantestEndTips />,
      percentage: 100,
    },
  ];

  useEffect(() => {
    setBarPercentage(cardsArray[card].percentage);
    if (card !== 3) toggleShowDynamicTips(true);
  }, [card]);

  const scrollCardTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    scrollCardTop();
  }, [card]);

  const callPreviousCard = () => setCard((card > 0) ? card - 1 : card);
  // const callPreviousCard = () => {
  //   registerAnalytics(`Vacantest/previous/page${card}`);
  //   return setCard((card > 0) ? card - 1 : card);
  // };
  const callNextCard = () => {
    const cardIsFilled = localStorageVacantest.check(card);
    // registerAnalytics(`Vacantest/next/page${card + 2}`);
    setCard((card < cardsArray.length - 1 && cardIsFilled) ? card + 1 : card);
  };

  const callToActions = (styles) => (
    <div className={vStyles[styles]}>
      {card !== 0 && (
        <button
          className={vStyles.vacantest__back__cta}
          type="button"
          onClick={() => callPreviousCard()}
        >
          <TiChevronLeft color="#333" size={20} />
          <p>ATRÁS</p>
        </button>
      )}
      {card < cardsArray.length - 1 && (
        <button
          id="vacantest_next_button"
          disabled={disabledBtn}
          title={disabledBtn ? 'Completa todos los datos por favor' : null}
          type="button"
          className={vStyles.vacantest__next__button}
          onClick={() => callNextCard()}
        >
          SIGUIENTE
        </button>
      )}
    </div>
  );

  const handleCloseTest = () => {
    localStorageVacantest.clear();
    navigate('/');
  };

  const renderCloseTestModal = () => (
    <div className={vStyles.close__test__modal}>
      <div className={vStyles.close__modal__btn__container}>
        <button
          type="button"
          className={vStyles.close__modal__btn}
          onClick={() => setCloseTestModal(!closeTestModal)}
        >
          <CgClose color="#fff" size={20} />
        </button>
      </div>
      <h3>Estás a punto de salir de tu Test de Compatibilidad</h3>
      <p>Si abandonas perderás la información que hayas cargado</p>
      <div className={vStyles.close__modal__test}>
        <button type="button" onClick={() => handleCloseTest()}>Cerrar</button>
      </div>
    </div>
  );

  return (
    <div className={vStyles.vacantest__container}>
      <Helmet>
        <title>Test de Compatibilidad - VacantED</title>
        <meta name="description" content="Te realizamos unas preguntas y te recomendamos los centros educativos más afines a tus preferencias y necesidades." />
      </Helmet>
      {closeTestModal && (
        <>
          <div className={vStyles.vacantest__masked} />
          <div className={vStyles.close__test__modal__container}>
            {renderCloseTestModal()}
          </div>
        </>
      )}
      <Header
        showUserMenu={false}
        showSearch={false}
        onClose={{
          action: () => setCloseTestModal(true),
          title: 'Cerrar',
        }}
        title="Test de Compatibilidad"
        fixed
        hideSlideMenu
      />
      <div className={vStyles.vacantest__header}>
        {/* <div className={vStyles.vacantest__header__top}>
          <div className={vStyles.vacanted__logo__container}>
            <img className={vStyles.vacanted__logo} src={vacantedLogo} alt="Vacanted" />
            <p>Test de Compatibilidad</p>
          </div>
          <div className={vStyles.vacantest__close}>
            <button type="button" onClick={() => setCloseTestModal(true)}>X Cerrar</button>
          </div>
        </div> */}
        <div className={vStyles.vacantest__percentage__bar__holder}>
          <div
            className={vStyles.vacantest__percentage__bar}
            style={{ width: `${barPercentage}%` }}
          >
            <div
              className={vStyles.vacantest__percentage__value}
            >
              {`${barPercentage}%`}
            </div>
          </div>
        </div>
      </div>
      <div className={vStyles.vacantest__dynamic__content}>
        <div className={vStyles.vacantest__card}>
          {/* <div className={vStyles.vacantest__empty} /> */}
          <div className={vStyles.vacantest__filled}>
            {cardsArray[card].content}
            {callToActions('vacantest__actions__desktop')}
          </div>
        </div>
        <div className={vStyles.vacantest__card__tips}>
          {showDynamicTips && cardsArray[card].tips}
        </div>
        {callToActions('vacantest__actions__mobile')}
      </div>
      {isLoginModalOpen && (
        <LoginModal
          onClose={() => setIsLoginModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Vacantest;
