import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ExperienceButton from '../../../../components/button';
import ExprienceMain from '../../../../components/main/view';
import ExperienceContext from '../../../../context/experience-context';
import FlowContext from '../../../../components/flow/context';

import './styles.scss';

const EmptyExperience = ({ onStart }) => {
  const {
    setCurrentPageId: setCurrentPageIdOld,
  } = useContext(ExperienceContext);
  const { setCurrentPageId: setCurrentPageIdFlow } = useContext(FlowContext);
  const setCurrentPageId = setCurrentPageIdFlow || setCurrentPageIdOld;
  if (setCurrentPageId) setCurrentPageId('landing');

  return (
    <ExprienceMain>
      <div className="empty-experience">
        <div className="empty-experience__welcome">
          <div className="welcome__title">
            Te damos la bienvenida a tu experiencia de
            {' '}
            <span className="welcome__title--highlite">
              búsqueda de centro educativo
            </span>
          </div>
          {/* <div className="welcome__subtitle">
            Completá los pasos y
            {' '}
            <b>
              obtené una lista de centros educativos candidatos
            </b>
            {' '}
            para solicitar entrevista y matricular.
          </div> */}
        </div>
        <div className="empty-experience__details">
          <div className="detail-list">
            <div className="detail-item">
              <div className="detail">
                <span className="detail__number">1</span>
                <div className="detail__container">
                  <div className="detail__title">Obtené recomendaciones</div>
                  <div
                    className="detail__text"
                  >
                    Ingresá tus preferencias y descubrí los centros más afines a tu búsqueda.
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-item">
              <div className="detail">
                <div className="detail__number">2</div>
                <div className="detail__container">
                  <div className="detail__title">
                    Analizá tus candidatos
                  </div>
                  <div
                    className="detail__text"
                  >
                    Podés compararlos, realizarles consultas y coordinar una visita.
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-item">
              <div className="detail">
                <div className="detail__number">3</div>
                <div className="detail__container">
                  <div className="detail__title">
                    Confirmá tu inscripción
                  </div>
                  <div
                    className="detail__text"
                  >
                    Informanos qué centro educativo elegiste y reclamá tus beneficios.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="experience__action">
            <ExperienceButton id="experience-button-start" chevron="right" hirachy="success" onClick={onStart} disabled={!onStart} loading={!onStart}>
              Comenzar
            </ExperienceButton>
          </div>
        </div>
      </div>
    </ExprienceMain>
  );
};

EmptyExperience.propTypes = {
  onStart: PropTypes.func,
};

EmptyExperience.defaultProps = {
  onStart: null,
};

export default EmptyExperience;
