import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import localStorageVacantest from '../../localStorageVacantest';
import VacantestDropdown from '../Dropdown/VacantestDropdown';
// import Switch from '../Switch/Switch';
import defaultStyles from '../DefaultCardStyles.module.css';
import specificStyles from './specificStyle.module.scss';

const VacantestStartDate = ({ card, disableNext }) => {
  const didMountRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptsInformation, setAcceptsInformation] = useState(true);
  const [startDate, setStartDate] = useState(undefined);

  const agesCollection = [
    { id: 0, name: 'Menos de 30 años' },
    { id: 1, name: 'Entre 30 y 40 años' },
    { id: 2, name: 'Más de 40 años' },
    { id: 3, name: 'No deseo informarla' },
  ];
  const [age, setAge] = useState({ name: 'Selecciona un rango' });

  const handleStarterDate = ({ target }) => {
    const { value } = target;
    setStartDate(value);
  };

  const handleAcceptsInformation = ({ target }) => {
    const { value } = target;
    setAcceptsInformation(value === 'YES');
  };

  const checkIfCardIsValid = () => typeof age?.id === 'number' && startDate;

  const saveDataInLocalStorage = () => {
    localStorageVacantest.save(
      card, {
        startDate, age, acceptsInformation,
      },
    );
    disableNext(false);
  };

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  useEffect(() => {
    if (didMountRef.current) {
      const cardValid = checkIfCardIsValid();
      if (cardValid) saveDataInLocalStorage();
      else removeCardFromLocalStorage();
    }
  }, [startDate, age, acceptsInformation]);

  const getInitialData = async () => {
    if (!didMountRef.current) {
      const defaultValues = localStorageVacantest.get(card);
      if (defaultValues) {
        setStartDate(defaultValues.startDate);
        setAcceptsInformation(defaultValues.acceptsInformation);
        setAge(defaultValues.age);

        disableNext(false);
      } else disableNext(true);
      didMountRef.current = true;
      setIsLoading(false);
    }
  };

  useEffect(() => getInitialData(), []);

  return (
    <div className={defaultStyles.vacantest__card__content}>
      {!isLoading ? (
        <>
          <h3>Define tus tiempos</h3>
          <div className={defaultStyles.vacantest__dropdown__container}>
            <div className="radio_btn">
              <div className="radios_btns">
                <form action="">
                  <p className={specificStyles.bold_text}>
                    ¿Para cuándo buscas una Institución Educativa?
                  </p>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="radio"
                      checked={startDate === 'THIS_YEAR'}
                      value="THIS_YEAR"
                      name="startDate"
                      onChange={handleStarterDate}
                    />
                    <p className={specificStyles.check__label}>Para este año</p>
                  </div>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="radio"
                      name="startDate"
                      checked={startDate === 'NEXT_YEAR'}
                      value="NEXT_YEAR"
                      onChange={handleStarterDate}

                    />
                    <p className={specificStyles.check__label}>Para el año que viene</p>
                  </div>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="radio"
                      name="startDate"
                      checked={startDate === 'EXPLORING'}
                      value="EXPLORING"
                      onChange={handleStarterDate}

                    />
                    <p className={specificStyles.check__label}>Estoy explorando simplemente</p>
                  </div>
                  <hr />
                  <p className={specificStyles.bold_text}>
                    Estoy de acuerdo con recibir información institucional de
                    instituciones educativas afines a mis preferencias.
                  </p>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="radio"
                      checked={acceptsInformation}
                      value="YES"
                      name="acceptsInformation"
                      onChange={handleAcceptsInformation}
                    />
                    <p className={specificStyles.check__label}>Sí (Recomendado)</p>
                  </div>
                  <div className={specificStyles.radio_inputs}>
                    <input
                      className={specificStyles.vacantest__checkbox}
                      type="radio"
                      checked={!acceptsInformation}
                      value="NO"
                      name="acceptsInformation"
                      onChange={handleAcceptsInformation}
                    />
                    <p className={specificStyles.check__label}>No</p>
                  </div>
                  {
                  /*
                  <div className={specificStyles.exploring__container}>
                    <p className={specificStyles.bold_text}>
                      Es probable que algún centro educativo de la lista de resultados de este test
                      te envíe información institucional por email. En el caso que no estés de
                      acuerdo en recibir información, hacé click en NO
                    </p>
                    <Switch isToggled={exploring} onToggle={() => setExploring(!exploring)} />
                  </div>
                  */
                  }
                </form>
              </div>
            </div>
            <hr />
            <div className={specificStyles.last__dropdown}>
              <p className={specificStyles.bold_text}>
                Indícanos tu rango de edad
              </p>
              <VacantestDropdown
                option={age}
                options={agesCollection}
                setOption={setAge}

              />
            </div>
          </div>
        </>
      ) : (
        <p className={defaultStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestStartDate;

VacantestStartDate.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
