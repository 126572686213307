import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MdLocationOn } from 'react-icons/md';
import countryService from 'services/countryService';
import api from 'services/api';
import { useSearch } from 'hooks/SearchContext';
import { removeDuplicateCities } from 'utils/cities/citiesFunctions';
import { getAddressSugestions, getLocation, fetchAddressByCoordinates } from 'utils/Coordinates/coordinatesFunctions';
import SimpleMap from 'components/MapView/SimpleMap';
import localStorageVacantest from '../../localStorageVacantest';
import VDropdown from '../Dropdown/VacantestDropdown';
import VInput from '../Input/VacantestInput';
import cStyles from '../DefaultCardStyles.module.css';

const VacantestLocation = ({ card, disableNext }) => {
  const countryId = countryService.getCurrentCountryId();
  // city
  const [city, setCity] = useState({ name: 'Elija una ciudad', id: 0 });
  const [activeCities, setActiveCities] = useState([]);
  const [locationCoords, setLocationCoords] = useState(undefined);
  const [mapLoading, setMapLoading] = useState(false);

  // address
  const [addressInput, setAddressInput] = useState({ search: false, value: '' });
  const [addressSugestions, setAddressSugestions] = useState([]);
  const [inputDropdownActive, setInputDropdownActive] = useState(false);
  const [inputDropdownLoading, setInputDropdownLoading] = useState(false);

  // general
  const [loading, setLoading] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [myLocation, setMyLocation] = useState(undefined);
  const { locationInfo } = useSearch();
  const mountRef = useRef(false);

  const removeCardFromLocalStorage = () => {
    localStorageVacantest.remove(card);
    disableNext(true);
  };

  const saveCardInLocalStorage = (data) => {
    const typeOfCoords = typeof (data.coords?.latitude);

    if (typeOfCoords && typeOfCoords !== 'number') {
      localStorageVacantest.save(card, {
        ...data,
        coords: {
          ...data.coords,
          latitude: Number(data.coords.latitude),
          longitude: Number(data.coords.longitude),
        },
      });
    } else localStorageVacantest.save(card, data);

    disableNext(false);
  };

  const updateDataByCity = async (data, coords) => {
    if (data) {
      const cityCenterCoords = { latitude: data.latitude, longitude: data.longitude };
      const coordsToUse = data.coords || coords || cityCenterCoords;
      setCity(data);
      setMapLoading(true);
      setLocationCoords(coordsToUse);
      if (data.coords) {
        setAddressInput({ search: false, value: data.address });
      } else if (!coords) {
        await fetchAddressByCoordinates(coordsToUse)
          .then((address) => {
            const formattedAddress = address.data.results[0].formatted_address;

            saveCardInLocalStorage({
              address: formattedAddress, coords: coordsToUse, name: data.name,
            });
            setAddressInput({ search: false, value: formattedAddress });
          });
      } else {
        saveCardInLocalStorage({ address: coords.address, coords: coordsToUse, name: data.name });
        setAddressInput({ search: false, value: coords.address });
      }

      setLocationError(false);
      setMapLoading(false);
    }
  };

  const updateDataByCoords = async (coords) => {
    setMapLoading(true);
    setLocationCoords(coords);

    removeCardFromLocalStorage();
    setAddressInput({ search: false, value: coords.address });
    setMapLoading(false);
  };

  // get default values
  const getDefaultCity = (citiesArray) => citiesArray.find((c) => c.id === locationInfo.cityId);
  const getActiveCities = async () => {
    const defaultFromLocalStorage = localStorageVacantest.get(card);
    if (!mountRef.current && locationInfo.cityId) {
      await api.get(`/city/${countryId}?active=true`)
        .then((ac) => {
          setActiveCities(removeDuplicateCities(ac)
            .sort((a, b) => a.name.localeCompare(b.name)));

          if (defaultFromLocalStorage) {
            updateDataByCity(defaultFromLocalStorage);
            disableNext(false);
          } else {
            const defaultCity = getDefaultCity(ac.data);
            updateDataByCity(defaultCity);
            disableNext(true);
          }
        });

      setLoading(false);
      mountRef.current = true;
    }
  };
  useEffect(() => {
    getActiveCities();
  }, [locationInfo]);

  // search for address x secs after the user stopped typing, cannot be an empty search
  useEffect(() => {
    setInputDropdownActive(false);

    if (addressInput.search && addressInput.value) {
      const delayAddressSearch = setTimeout(async () => {
        setInputDropdownActive(true);
        setInputDropdownLoading(true);
        await getAddressSugestions(addressInput.value, setAddressSugestions);
        setInputDropdownLoading(false);
      }, 1000);

      return () => clearTimeout(delayAddressSearch);
    }

    return () => {};
  }, [addressInput]);

  const getActiveCityFromAddress = (name) => activeCities
    .filter((ac) => ac.name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(name.toLowerCase()));

  // updates map when address is selected
  const setSelectedOption = async (item) => {
    const cityName = () => {
      if (item.terms.length > 0) {
        const ix = countryId === 101 ? item.terms.length - 3 : item.terms.length - 2;
        return item.terms[ix].value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }

      return '';
    };

    let cityFromAddress = getActiveCityFromAddress(cityName());

    api.get(`google/coords?placeId=${item.place_id}`)
      .then((c) => {
        const { lat, lng } = c.data.result.geometry.location;
        const coords = { latitude: lat, longitude: lng, address: item.description };
        if (cityFromAddress.length > 0) {
          cityFromAddress = cityFromAddress.reduce((cfa) => cfa);
          updateDataByCity(cityFromAddress, coords);
        } else {
          updateDataByCoords(coords);
        }
      });
  };

  const isFromCountry = (payload) => {
    const expectedCountryName = countryService.getCurrentCountryName();
    const countryName = payload.data.results.filter((r) => r.types.includes('country'));
    return countryName[0].formatted_address === expectedCountryName;
  };
  const getAddress = async () => {
    setMapLoading(true);
    setLocationCoords(myLocation);
    await fetchAddressByCoordinates(myLocation)
      .then((a) => {
        if (!isFromCountry(a)) {
          setLocationError(true);
          removeCardFromLocalStorage();
        } else {
          setLocationError(false);
          saveCardInLocalStorage({
            address: a.data.results[0].formatted_address, coords: myLocation, name: '',
          });
        }

        setAddressInput({ search: false, value: a.data.results[0].formatted_address });
      });
    setMapLoading(false);
  };

  useEffect(() => {
    if (myLocation) getAddress();
  }, [myLocation]);

  const getMyLocation = async () => {
    setMapLoading(true);
    getLocation(setMyLocation, setMyLocation);
    setTimeout(() => setMapLoading(false), 500);
  };

  return (
    <div className={cStyles.vacantest__card__content}>
      {!loading ? (
        <>
          <h3>¡Hola, bienvenido al Test de Compatibilidad!</h3>
          <p>
            Vamos a realizarte unas preguntas con el fin de
            recomendarte los centros educativos
            más afines a tus preferencias y necesidades.
          </p>
          <p>¡Confía en el algoritmo VACANTED™!</p>
          <h3>Ingresa tu ubicación </h3>
          <p>
            Comienza por ubicar el lugar geográfico a partir del cual
            quieres centrar tu búsqueda de instituciones educativas.
          </p>

          <div>
            <button
              type="button"
              className={cStyles.vacantest__location__button}
              onClick={() => getMyLocation()}
            >
              <MdLocationOn size={20} className={cStyles.vacantest__location__button__icon} />
              Usar la ubicación actual
            </button>
          </div>

          <h4>Ciudades habilitadas</h4>
          <VDropdown
            option={city}
            setOption={updateDataByCity}
            options={activeCities}
          />

          <h4>Calle</h4>
          <VInput
            placeholder="Ingresa una dirección"
            loading={inputDropdownLoading}
            isActive={inputDropdownActive}
            inputValue={addressInput.value}
            setInputValue={setAddressInput}
            dropdownOptions={addressSugestions}
            setSelectedOption={setSelectedOption}
          />
          {locationError && (
            <p className={cStyles.vacantest__error__message}>
              La dirección no corresponde a una de las ciudades habilitadas
            </p>
          )}

          <div className={cStyles.vacantest__map__container}>
            {(!mapLoading && locationCoords) ? (
              <SimpleMap
                latitude={locationCoords.latitude}
                longitude={locationCoords.longitude}
                heightSize="100%"
              />
            ) : (
              <p className={cStyles.vacantest__map__loading}>...cargando</p>
            )}
          </div>
        </>
      ) : (
        <p className={cStyles.loading__text}>...cargando</p>
      )}
    </div>
  );
};

export default VacantestLocation;

VacantestLocation.propTypes = {
  card: PropTypes.number.isRequired,
  disableNext: PropTypes.func.isRequired,
};
